import { Navigate, Route } from "react-router";
import {
  AccountDetailsPage,
  ActivityPage,
  ExpertsPage,
  MyProgramsPage,
  SettingsPage,
  TodayPage,
  UserOnboarding,
} from "../pages";
import ProgramsPage from "../pages/user-view/ProgramsPage";
import { userRoutes } from "../constants/routes/user";

export const useUserRoutes = () => {
  return (
    <>
      <Route path={userRoutes.onboarding} element={<UserOnboarding />} />
      <Route
        path={userRoutes.accountDetails}
        element={<AccountDetailsPage />}
      />
      <Route path={userRoutes.today} element={<TodayPage />} />
      <Route path={userRoutes.activity} element={<ActivityPage />} />
      <Route path={userRoutes.myPrograms} element={<MyProgramsPage />} />
      <Route path={userRoutes.explore.experts} element={<ExpertsPage />} />
      <Route path={userRoutes.explore.programs} element={<ProgramsPage />} />
      <Route path={userRoutes.settings} element={<SettingsPage />} />

      <Route path="*" element={<Navigate replace to={userRoutes.today} />} />
    </>
  );
};
