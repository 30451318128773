export const userRoutes = {
  onboarding: "/profile/onboarding",
  accountDetails: "/profile/account-details",
  today: "/today",
  activity: "/activity",
  myPrograms: "/my-programs",
  explore: {
    experts: "/explore/experts",
    programs: "/explore/programs",
  },
  settings: "/settings",
};
