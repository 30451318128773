import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../config/axios-instance";
import { MarketplaceSupplementData } from "../../schemas/marketplace/marketplace";

export function useSupplementToProgramMutation() {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({
      programId,
      data,
    }: {
      programId: number | null;
      data: MarketplaceSupplementData;
    }) => {
      if (!programId) return;
      const response = await coreServiceAxios.post(
        `/api/health/program/${programId}/supplements`,
        { supplementId: data.supplementId, usage: data.usage }
      );
      return response.data;
    },
    onSuccess: (variable) => {
      showSnackbar(
        "success",
        "You have added the supplement to the program successfully.",
        3000
      );
      queryClient.invalidateQueries({
        queryKey: [`/healthProgram/${variable.programId}`],
      });
    },
    onError: (error) => {
      showSnackbar(
        "error",
        "Something went wrong. Please try again later.",
        3000
      );
    },
  });
}
