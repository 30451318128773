import { Box, Chip, Typography } from "@mui/material";
import { ReusableButton } from "../../reusable";
import { useObjectURL } from "../../../hooks/object-url";

const MealTypeChip = ({
  text,
  variant = "outlined",
}: {
  text: string;
  variant?: "outlined" | "filled";
}) => {
  return (
    <Chip
      label={text}
      variant={variant}
      sx={{
        ...(variant === "outlined"
          ? {
              color: "#2974B1",
              borderColor: "#2974B1",
              backgroundColor: "#ffffff",
            }
          : {
              color: "#ffffff",
              backgroundColor: "#2974B1",
              border: "none",
            }),
        fontSize: "14px",
        fontWeight: 500,
        borderRadius: "16px",
        paddingX: "12px",
        paddingY: "2px",
      }}
    />
  );
};

type Props = {
  meal: Meal;
  handleOpenAddToProgramModal: () => void;
};

const MealLibraryCard = ({ meal, handleOpenAddToProgramModal }: Props) => {
  const imagePreview =
    useObjectURL(meal?.file) || "/assets/meal-placeholder.png";

  return (
    <Box
      sx={{
        width: "100%",
        height: 470,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        border: "1px solid #E4E7EC",
        borderRadius: "12px",
        padding: "24px 12px 18px 12px",
      }}
    >
      <Box
        sx={{
          height: "163px",
          width: "100%",
          flexShrink: 0,
          overflow: "hidden",
          position: "relative",
          borderRadius: "12px",
          mb: 1,
        }}
      >
        <img
          src={imagePreview}
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Typography
        fontWeight={900}
        fontSize="16px"
        lineHeight="22.4px"
        color="#000000"
        height={50}
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        }}
      >
        {meal.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <MealTypeChip text={meal.dietPlan} />
        <MealTypeChip text={meal.type} variant="filled" />
        <Typography fontSize={14} fontWeight={400} color="#7D8189">
          {`${meal.calories} Calories`}
        </Typography>
      </Box>
      <Typography
        fontSize={16}
        fontWeight={400}
        color="#7D8189"
        my={1}
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        }}
      >
        {meal.description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          mt: "auto",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography fontSize={14} fontWeight={600} color="#7D8189">
          {`${meal.createdBy.firstName} ${meal.createdBy.lastName}`}
        </Typography>
        <ReusableButton
          buttonText={"Add to Program"}
          width="50%"
          height="32px"
          onClickFunction={handleOpenAddToProgramModal}
          backgroundColor="#2974B1"
          beforeIcon={<img src="/assets/icon-plus.svg" alt="icon" />}
        />
      </Box>
    </Box>
  );
};

export default MealLibraryCard;
