import { Box } from "@mui/material";
import { SectionHeader } from "../programs/widgets";
import {
  ReusableAutocomplete,
  ReusableRadioButtons,
  ReusableTextField,
} from "../reusable";
import { Control } from "react-hook-form";
import {
  EmailOutlined,
  Person2Outlined,
  PersonPinOutlined,
} from "@mui/icons-material";
import CountryDialCodeSelect from "./CountryDialCodeSelect";
import { countries, CountryType } from "../../constants/countries";
import PasswordTextField from "./PasswordTextField";
import { interestSourceOptions } from "../../constants/registration";
import { renderCountryOption } from "../../pages/shared-view/RegisterPage";

const PersonalInformation = ({
  control,
  countryDialCode,
  setCountryDialCode,
}: {
  control: Control<any>;
  countryDialCode: CountryType;
  setCountryDialCode: React.Dispatch<React.SetStateAction<CountryType>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        justifyContent: "center",
        alignItems: "center",
        width: "700px",
      }}
    >
      <SectionHeader
        title="Personal Information"
        subtitle="Fill in your personal information here in order to get the best recommendations"
      />
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="firstName"
            label="First Name"
            placeholder="Enter first name"
            autoComplete="firstName"
            isRequired={true}
            autofocus={true}
            startAdornment={<Person2Outlined />}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="lastName"
            label="Last Name"
            placeholder="Enter last name"
            autoComplete="lastName"
            isRequired={true}
            startAdornment={<Person2Outlined />}
          />
        </Box>
        <ReusableTextField
          inputType="email"
          control={control}
          name="email"
          label="Email"
          placeholder="example@email.com"
          autoComplete="email"
          isRequired={true}
          startAdornment={<EmailOutlined />}
          disabled={true}
        />
        <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="username"
            label="Username"
            autoComplete="username"
            isRequired={true}
            // tooltipText="Username"
            startAdornment={<PersonPinOutlined />}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="phoneNumber"
            label="Phone Number"
            autoComplete="phoneNumber"
            isRequired={true}
            // tooltipText="Phone Number"
            startAdornment={
              <CountryDialCodeSelect
                chosenCountry={countryDialCode}
                setChosenCountry={setCountryDialCode}
              />
            }
          />
        </Box>
        <PasswordTextField
          inputType="password"
          control={control}
          name="password"
          id="password"
          label="Password"
          autoComplete="password"
        />
        <ReusableTextField
          inputType="password"
          control={control}
          name="confirmPassword"
          label="Confirm Password"
          autoComplete="confirmPassword"
        />
        <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
          <ReusableAutocomplete
            name="country"
            label="Country"
            control={control}
            options={countries.map((country) => ({
              id: country.code,
              label: country.label,
            }))}
            renderOption={renderCountryOption}
            searchIcon={true}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="zipCode"
            label="Zip Code"
            placeholder="123"
            isRequired={true}
            tooltipText="Zip code of your main property"
          />
        </Box>
        <ReusableRadioButtons
          name="interestSource"
          control={control}
          options={interestSourceOptions}
          label=" How did you hear about Fresh Earth?"
        />
      </Box>
    </Box>
  );
};

export default PersonalInformation;
