import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEditUserMutation } from "../../../api/users/editUserMutation";
import { ContactData } from "../../../types/account-details";
import { countries, CountryType } from "../../../constants/countries";
import { useForm } from "react-hook-form";
import { ReusableTextField } from "../../reusable";
import { CountryDialCodeSelect } from "../../registration";
import { useSnackbar } from "../../snackbar/Snackbar";
import {
  ContactDataInitialValues,
  contactSchema,
} from "../../../schemas/user/account-details";
import { defaultCountry } from "../../../schemas/user/onboarding";
import { User } from "../../../types/user/user";

type Props = {
  user?: User;
  isForUserManagement?: boolean;
  onCloseModal?: any;
};

type InputProps = {
  name: string;
  label: string;
  placeholder: string;
  type: string;
  multiline?: boolean;
  rows?: number;
  isRequired?: boolean;
  disabled?: boolean;
};

const ContactDetailsForm = ({
  user,
  isForUserManagement,
  onCloseModal,
}: Props) => {
  const { mutateAsync } = useEditUserMutation();

  const [contactData] = useState<ContactData>(ContactDataInitialValues);
  const [countryDialCode, setCountryDialCode] =
    useState<CountryType>(defaultCountry);

  const { showSnackbar } = useSnackbar();

  const { handleSubmit, control, setValue } = useForm<ContactData>({
    defaultValues: contactData,
    resolver: yupResolver(contactSchema),
  });

  const onSubmit = async (value: ContactData) => {
    setValue("countryDialCodeNumber", countryDialCode.phone);
    const response = await mutateAsync({ id: user?.id!, data: value });
    if (response?.error) {
      showSnackbar("error", response.error.message);
    }
    if (isForUserManagement) {
      onCloseModal(response);
    }
    return response;
  };

  useEffect(() => {
    if (user) {
      setValue("username", user.username || "");
      setValue("firstName", user.firstName || "");
      setValue("lastName", user.lastName || "");
      setValue("email", user.email || "");
      setValue("phoneNumber", user.phoneNumber || "");
      setValue("country", user.country || "");
      setValue("state", user.state || "");
      setValue("city", user.city || "");
      setValue("postCode", user.postCode || "");
      setValue("bio", user.bio || "");
      setValue("billingAddress", user.billingAddress || "");
      setValue("userId", user.userId || "");
      const ownerCountryDialCodeObject = countries.find(
        (x) => x.phone === user.countryDialCodeNumber
      );
      if (ownerCountryDialCodeObject) {
        setCountryDialCode(ownerCountryDialCodeObject);
      }
    }
  }, [user, setValue]);

  const Input = ({
    name,
    label,
    placeholder,
    type,
    rows,
    multiline = false,
    isRequired = true,
    disabled = false,
  }: InputProps) => {
    return (
      <ReusableTextField
        name={name}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        inputType={type}
        control={control}
        borderRadius={"10px"}
        multiline={multiline}
        rows={rows}
        isRequired={isRequired}
      />
    );
  };

  return (
    <Box component="form" onSubmit={(e) => e.preventDefault()}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button
          disableRipple
          size="small"
          variant="outlined"
          sx={{
            textTransform: "none",
            borderRadius: "10px",
            bgcolor: "#e9f0ea",
            border: "1px solid black",
            color: "black",
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Box>
      <Grid container spacing={4}>
        {/* Left column */}
        <Grid
          item
          lg={6}
          spacing={2}
          sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <Input
            name="firstName"
            label="First Name"
            placeholder="Example"
            type="text"
          />
          <Input
            name="username"
            label="Username"
            placeholder="Example Account Username"
            type="text"
          />
          <Input
            name="email"
            label="Email"
            placeholder="example@email.com"
            type="text"
            disabled={true}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="phoneNumber"
            label="Phone Number"
            autoComplete="phone"
            isRequired={false}
            startAdornment={
              <CountryDialCodeSelect
                chosenCountry={countryDialCode}
                setChosenCountry={setCountryDialCode}
              />
            }
          />
        </Grid>

        {/* Right column */}
        <Grid item lg={6} className="contact-form-grid-item">
          <Input
            name="lastName"
            label="Last Name"
            placeholder="Account"
            type="text"
          />
          <Input
            name="userId"
            label="User ID"
            placeholder="14916546510766"
            type="text"
            isRequired={false}
          />
          <Grid container direction="row" spacing={2}>
            <Grid item lg={7}>
              <Input
                name="billingAddress"
                label="Billing Address"
                placeholder=""
                type="text"
                isRequired={false}
              />
            </Grid>
            <Grid item lg={5}>
              <Input
                name="country"
                label="Country"
                placeholder=""
                type="text"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={4}>
              <Input
                name="state"
                label="State"
                placeholder=""
                type="text"
                isRequired={false}
              />
            </Grid>
            <Grid item lg={4}>
              <Input
                name="city"
                label="City/Suburb"
                placeholder=""
                type="text"
                isRequired={false}
              />
            </Grid>
            <Grid item lg={4}>
              <Input
                name="postCode"
                label="Postcode"
                placeholder=""
                type="text"
                isRequired={false}
              />
            </Grid>
          </Grid>
          <Input
            name="bio"
            label="Bio"
            placeholder=""
            type="text"
            multiline={true}
            rows={5}
            isRequired={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactDetailsForm;
