import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import ProgramCard from "./ProgramCard";
import { ProgramSkeleton, SectionHeader } from "../widgets";
import { ReusableButton } from "../../reusable";
import Filters from "./Filters";
import { useNavigate } from "react-router";
import { useProgramQuery } from "../../../api/programs/programQuery";
import { searchInputSchema } from "../../../schemas/programs/programs";

const AllPrograms: React.FC = () => {
  const { watch, control } = useForm({
    defaultValues: { name: "" },
    resolver: yupResolver(searchInputSchema),
  });
  const [scrollPlaceholder, setScrollPlaceholder] =
    useState<HTMLElement | null>(null);

  const navigate = useNavigate();

  const name = watch("name");

  const { data, isLoading, fetchNextPage, isFetched } = useProgramQuery({
    name: name ?? "",
  });

  useEffect(() => {
    if (!scrollPlaceholder) {
      return;
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        return;
      }

      fetchNextPage();
    });

    intersectionObserver.observe(scrollPlaceholder);

    return () => intersectionObserver.disconnect();
  }, [scrollPlaceholder, fetchNextPage]);

  const handleProgramOnClick = (id: string) => {
    navigate(`/program/${id}`);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginY: "20px",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Filters control={control} />
            <ReusableButton
              onClickFunction={() => navigate("/program/create")}
              buttonText={"Add new program"}
              width="15%"
              afterIcon={<img src="/assets/icon-plus.svg" alt="icon" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              gap: 1.75,
            }}
          >
            {isLoading && (
              <>
                <SectionHeader title="All Programs" />
                {[1, 2, 3].map((_, index) => (
                  <ProgramSkeleton key={index} />
                ))}
              </>
            )}
            {!isLoading && data && data.pages && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 3,
                }}
              >
                <SectionHeader title="All Programs" />

                {data.pages.map((obj) =>
                  obj.data.map((obj, index) => (
                    <ProgramCard
                      key={index}
                      programObject={obj}
                      handleOnClick={() =>
                        handleProgramOnClick(obj.id.toString())
                      }
                    />
                  ))
                )}
                {isFetched && data?.pages[0]?.pagination.total === 0 && (
                  <p>No programs found</p>
                )}
              </Box>
            )}
            <Box ref={setScrollPlaceholder}></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllPrograms;
