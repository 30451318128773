import { Box, ThemeProvider } from "@mui/material";
import AllPrograms from "../../../components/programs/allPrograms/AllPrograms";
import { allProgramsCustomTheme } from "../../../constants/programs/customThemes";

const Programs = () => {
  return (
    <ThemeProvider theme={allProgramsCustomTheme}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AllPrograms />
      </Box>
    </ThemeProvider>
  );
};

export default Programs;
