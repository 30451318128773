import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { APPLICATION_TYPE } from "../../constants/registration";
import { AllUsersResponse } from "../../types/user/user";

export function useAllUsersQuery() {
  const { hasToken } = useAuthToken();

  return useQuery({
    queryKey: ["/users/all"],
    queryFn: async () => {
      const response = await coreServiceAxios.post<AllUsersResponse>(
        `/api/users/all`,
        { applicationType: APPLICATION_TYPE }
      );
      return response.data;
    },
    enabled: hasToken,
  });
}

type UseAllUsersQueryProps = {
  searchText?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const useAllUsersInfiniteQuery = ({
  searchText,
  pageSize,
  pageNumber,
}: UseAllUsersQueryProps) => {
  return useInfiniteQuery({
    queryKey: ["users/all", searchText],
    queryFn: async ({ pageParam = 1 }) => {
      const queryParameters = `page=${pageParam}&pageSize=${pageSize || 10}`;

      const filters = [searchText ? `searchTerm=${searchText}` : null]
        .filter(Boolean)
        .join("&");

      const endpoint = `/api/users/all?${queryParameters}${
        filters ? `&${filters}` : ""
      }`;

      const response = await coreServiceAxios.post(endpoint, {
        applicationType: APPLICATION_TYPE,
      });
      return response.data;
    },
    initialPageParam: pageNumber || 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
};
