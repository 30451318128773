import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { Supplements } from "../../types/supplements";
import { useUsersMeQuery } from "../users/usersMeQuery";

interface UseSupplementsQueryProps {
  search?: string;
  sort?: string;
  pageSize?: number;
  programs?: string[];
  maxPrice?: number;
  type?: string[];
  supplier?: string[];
}

interface SupplementsData {
  data: Supplements[];
  pagination: {
    pageCount: number;
    total: number;
  };
}

export function useSupplementsInfiniteQuery(props: UseSupplementsQueryProps) {
  const { hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();

  const filters = [
    props.search ? `search=${props.search}` : null,
    props.sort
      ? `sort=${
          props.sort === "Name (A-Z)"
            ? "name_asc"
            : props.sort === "Name (Z-A)"
            ? "name_desc"
            : props.sort === "Price (Low to High)"
            ? "price_asc"
            : "price_desc"
        }`
      : null,
    props.maxPrice ? `maxPrice=${props.maxPrice.toString()}` : null,
  ];

  if (props?.programs) {
    props?.programs?.forEach((program, index) => {
      filters.push(`programs[${index}]=${program}`);
    });
  }
  if (props?.type) {
    props?.type?.forEach((t, index) => {
      filters.push(`type[${index}]=${t}`);
    });
  }
  if (props?.supplier) {
    props?.supplier?.forEach((t, index) => {
      filters.push(`supplier[${index}]=${t}`);
    });
  }

  const joinedFilters = filters.filter(Boolean).join("&");

  return useInfiniteQuery({
    queryKey: [
      "api/supplements",
      props.programs,
      props.maxPrice,
      props.pageSize,
      props.search,
      props.type,
      props.supplier,
      props.sort,
    ],
    queryFn: async ({ pageParam }) => {
      const queryParameters = `page=${pageParam}&pageSize=${
        props.pageSize ?? 6
      }`;

      const endpoint = `${
        process.env.REACT_APP_CORE_SERVICE_URL
      }/api/health/program/supplements?${queryParameters}${
        joinedFilters ? `&${joinedFilters}` : ""
      }`;

      const response = await coreServiceAxios.get<SupplementsData>(endpoint);

      return response.data;
    },
    enabled: !!hasToken && me?.id !== undefined,
    initialPageParam: 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
}
