import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ContentSection from "../../components/landing/ContentSection";
import HeaderImageWrapper from "../../components/landing/HeaderImageWrapper";
import SubHeaderSection from "../../components/landing/SubHeaderSection";

const MainPage = () => {
  const [isHealthProgramExpanded, setIsHealthProgramExpanded] = useState(false);
  const [isHealthExpertsExpanded, setIsHealthExpertsExpanded] = useState(false);
  const [isSuppliersSectionExpanded, setIsSuppliersSectionExpanded] =
    useState(false);

  const [isInvestorsSectionExpanded, setisInvestorsSectionExpanded] =
    useState(false);

  const handleHealthProgramReadMore = () => {
    setIsHealthProgramExpanded(!isHealthProgramExpanded);
  };
  const handleHealthExpertsReadMore = () => {
    setIsHealthExpertsExpanded(!isHealthExpertsExpanded);
  };
  const handleSuppliersSectionReadMore = () => {
    setIsSuppliersSectionExpanded(!isSuppliersSectionExpanded);
  };

  const handleInvestorsSectionReadMore = () => {
    setisInvestorsSectionExpanded(!isInvestorsSectionExpanded);
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <HeaderImageWrapper
        backgroundImage="/assets/header-image.jpg"
        text="Fresh Earth"
      />
      <SubHeaderSection text="Fresh Earth stands for truth, empowering communities to build a resilient, eco-friendly food system." />
      <Box>
        {/* Health Program Section */}
        <ContentSection
          title="Users (Health Program Participants)"
          bodyText="Fresh Earth connects you with expert-designed health programs tailored to your needs, offering a clear, trustworthy path to better health with the support of top professionals."
          expandedText={
            <>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Expert-Guided:
              </Typography>{" "}
              Fresh Earth connects you with health programs tailored by top
              professionals, ensuring every step—from diet to diagnostics—is
              backed by science.
              <br />
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Transparent:
              </Typography>{" "}
              We provide clear, trustworthy information so you understand the
              “why” behind every choice, guiding you with expert-driven,
              evidence-based decisions.
              <br />
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Supportive:
              </Typography>{" "}
              Experience a safe, effective health transformation with ongoing
              support from the experts who crafted your program, ensuring your
              path to wellness is smooth and successful.
            </>
          }
          isExpanded={isHealthProgramExpanded}
          onToggleExpand={handleHealthProgramReadMore}
          buttonText="Read More"
          imagePosition="right"
          imageSrc="/assets/main-page-sit-ups.jpg"
          imageAlt="main-page-sit-ups"
          backgroundColor="rgba(57, 146, 221, 0.2)"
          overlayImageSrc="/assets/main-page-running.jpg"
        />
      </Box>

      {/* Health Experts Section */}
      <ContentSection
        title="Health Experts (Program Designers)"
        bodyText="Fresh Earth empowers you with the tools to create and validate health programs, allowing your expertise to shine through in comprehensive, marketable solutions."
        expandedText={
          <>
            <Typography
              component="span"
              sx={{ fontWeight: 700, marginRight: "4px" }}
            >
              Empowering:
            </Typography>
            Fresh Earth provides the tools and technology to create
            comprehensive health programs that showcase your expertise and
            innovation.
            <Box component="span" sx={{ display: "block", marginTop: "8px" }}>
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginRight: "4px" }}
              >
                Collaborative:
              </Typography>
              Work with dietitians, chefs, and other professionals in a
              collaborative environment where your programs can reach a broad,
              engaged audience, and data can drive innovation and success.
            </Box>
            <Box component="span" sx={{ display: "block", marginTop: "8px" }}>
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginRight: "4px" }}
              >
                Credible:
              </Typography>
              Build programs with confidence, supported by peer-reviewed
              science, ensuring your offerings are credible and enhance your
              reputation.
            </Box>
          </>
        }
        isExpanded={isHealthExpertsExpanded}
        onToggleExpand={handleHealthExpertsReadMore}
        buttonText="Read More"
        imagePosition="left"
        imageSrc="/assets/health-experts-image.jpg"
        imageAlt="health-experts-image"
        backgroundColor="rgba(253, 253, 253, 1)"
      />

      {/* Suppliers Section */}
      <ContentSection
        title="Suppliers (Product Providers)"
        bodyText="Integrate your products into expert-led health programs on Fresh Earth, enhancing their credibility and reach within scientifically grounded solutions."
        expandedText={
          <>
            <Typography
              component="span"
              sx={{ fontWeight: 700, marginRight: "4px" }}
            >
              Integrated:
            </Typography>
            Seamlessly integrate your products into expertly designed health
            programs, ensuring they are part of scientifically grounded
            solutions.
            <Box component="span" sx={{ display: "block", marginTop: "8px" }}>
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginRight: "4px" }}
              >
                Credible:
              </Typography>
              Enhance your product’s credibility by being included in programs
              crafted by leading health experts.
            </Box>
            <Box component="span" sx={{ display: "block", marginTop: "8px" }}>
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginRight: "4px" }}
              >
                High-Quality:
              </Typography>
              Our platform supports the integration of high-quality products
              that meet strict standards, ensuring they are trusted by users.
            </Box>
          </>
        }
        isExpanded={isSuppliersSectionExpanded}
        onToggleExpand={handleSuppliersSectionReadMore}
        buttonText="Read More"
        imagePosition="right"
        imageSrc="/assets/suppliers-section-image.jpg"
        imageAlt="suppliers-image"
        backgroundColor="rgba(57, 146, 221, 0.1)"
      />

      {/* Investors Section */}
      <ContentSection
        title="Investors"
        bodyText="Invest in data-driven health programs designed by qualified experts on Fresh Earth. Your funds fuel the creation, validation, and scaling of scientifically-backed solutions. By supporting these programs at their inception, you become a seed investor in initiatives that are poised to scale with credibility, unlocking significant potential for impact and valuable innovative data-driven returns."
        expandedText={
          <>
            <Typography
              component="span"
              sx={{ fontWeight: 700, marginRight: "4px" }}
            >
              Impactful:
            </Typography>
            Invest in health programs on Fresh Earth, where your funds support
            the creation and validation of scientifically backed, scalable
            solutions.
            <Box component="span" sx={{ display: "block", marginTop: "8px" }}>
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginRight: "4px" }}
              >
                Valuable:
              </Typography>
              Monetize the rich, data-driven potential of these programs,
              offering significant opportunities for returns.
            </Box>
            <Box component="span" sx={{ display: "block", marginTop: "8px" }}>
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginRight: "4px" }}
              >
                Secure:
              </Typography>
              Invest confidently with Fresh Earth’s solid framework, focusing on
              compliance and transparency to ensure secure, trusted investments.
            </Box>
          </>
        }
        isExpanded={isInvestorsSectionExpanded}
        onToggleExpand={handleInvestorsSectionReadMore}
        buttonText="Read More"
        imagePosition="left"
        imageSrc="/assets/investors-section-image.jpg"
        imageAlt="investors-image"
        backgroundColor="rgba(253, 253, 253, 1)"
      />
    </Box>
  );
};

export default MainPage;
