import { Box, LinearProgress, Typography } from "@mui/material";
import { ReusableButton } from "../../reusable";

const DayProgressBadge = () => {
  return (
    <Box
      sx={{
        padding: "6px 12px",
        borderRadius: "12px",
        background: "linear-gradient(90deg, #F2B9FD 0%, #3992DD 100%)",
        textAlign: "center",
      }}
    >
      <Typography fontSize={12} color="white">
        Day 3 of 30
      </Typography>
    </Box>
  );
};

const ActiveProgram = () => {
  const progressPercent = 25;
  return (
    <Box
      sx={{
        height: 166,
        display: "flex",
        alignItems: "center",
        border: "1px solid #E4E7EC",
        borderRadius: 2,
      }}
    >
      <Box sx={{ width: 125, height: 116, mx: 3, flexShrink: 0 }}>
        <img
          alt="program"
          src="/assets/my-programs/ap-placeholder1.png"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          py: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "80%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color="black"
            fontFamily="Comfortaa"
          >
            Longetivity
          </Typography>
          <DayProgressBadge />
        </Box>
        <LinearProgress
          variant="determinate"
          value={progressPercent}
          sx={{
            width: "98%",
            height: "5.44px",
            borderRadius: 10,
            backgroundColor: "#E0E0E0",
            "& .MuiLinearProgress-bar": {
              borderRadius: 10,
              background: `linear-gradient(90deg, #F2B9FD ${
                100 - progressPercent
              }%, #3992DD 100%)`,
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
              <img
                alt="calendar"
                src="/assets/my-programs/ap-calendar-icon.svg"
              />
              <Typography fontSize={14} color="#615959" width={70}>
                12 Weeks Total
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
              <img
                alt="calendar"
                src="/assets/my-programs/ap-progress-icon.svg"
              />
              <Typography fontSize={14} color="#615959" width={70}>
                {`${progressPercent}% Completed`}
              </Typography>
            </Box>
          </Box>
          <ReusableButton
            buttonText={"Program overview"}
            width="157px"
            height="30px"
            onClickFunction={() => {}}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveProgram;
