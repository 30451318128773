import { Box, MenuItem, Modal, Select, Typography } from "@mui/material";
import { ReusableButton } from "../../reusable";
import RichTextDisplay from "../../reusable/RichTextDisplay/RichTextDisplay";
import { useObjectURL } from "../../../hooks/object-url";

const HeaderBadge = ({ text, icon }: { text: string; icon: string }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexShrink: 0, gap: 1 }}>
      <img alt="badge" src={icon} />
      <Typography fontSize={16} fontWeight={700} color="#2974B1">
        {text}
      </Typography>
    </Box>
  );
};

type Props = {
  meal: Meal;
  open: boolean;
  handleClose: () => void;
};

const MealLibraryAddToProgramModal = ({ meal, open, handleClose }: Props) => {
  const image = useObjectURL(meal?.file) || "/assets/meal-placeholder.png";
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "white",
          boxShadow: 5,
          outline: "none",
          borderRadius: "10px",
          maxHeight: "98vh",
          overflowY: "auto",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            width: "750px",
          }}
        >
          <Typography
            fontSize={40}
            fontWeight={400}
            color="#2974B1"
            fontFamily="Comfortaa"
          >
            {meal.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              width: "100%",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                fontSize={16}
                fontWeight={700}
                color="black"
                mr="auto"
              >
                {`${meal.createdBy.firstName} ${meal.createdBy.lastName}`}
              </Typography>
              <Typography fontSize={16} fontWeight={400} color="#2974B1">
                Chef
              </Typography>
            </Box>
            <HeaderBadge
              icon="/assets/diet-type-icon.svg"
              text={meal.dietPlan}
            />
            <HeaderBadge icon="/assets/meal-type-icon.svg" text={meal.type} />
            <HeaderBadge
              icon="/assets/calories-icon.svg"
              text={`${meal.calories} kcal`}
            />
            <HeaderBadge
              icon="/assets/prep-time-icon.svg"
              text={`${meal.preparationTime} min`}
            />
          </Box>
          <Box
            sx={{
              width: "749px",
              height: "365px",
              borderRadius: 3,
              overflow: "hidden",
              mt: 2,
            }}
          >
            <img
              alt="meal"
              src={image}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Typography fontSize={16} fontWeight={600} color="black" mt={2}>
            {meal.description}
          </Typography>
          <RichTextDisplay content={meal.recipe} />
          <RichTextDisplay content={meal.references} />
          <Typography
            fontSize={24}
            fontWeight={600}
            color="black"
            alignSelf="flex-start"
            mt={3}
            mb={1}
          >
            Add Meal to Program
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              textAlign: "left",
              marginBottom: "4px",
              alignSelf: "flex-start",
            }}
          >
            Select the program from the list
          </Typography>
          <Select
            size="small"
            displayEmpty
            value={null}
            MenuProps={{
              sx: { height: "300px" },
            }}
            sx={{
              alignSelf: "flex-start",
              maxHeight: "50px",
              width: "100%",
              mt: 0.5,
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2974B1",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2974B1",
              },
            }}
            renderValue={(value: any) =>
              value ? (
                value
              ) : (
                <Typography color="grey">{"Select program"}</Typography>
              )
            }
          >
            <MenuItem value="__clear__">
              <Typography fontSize={16} fontWeight={400} color="grey">
                Select option
              </Typography>
            </MenuItem>
            {["Neki program"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 3,
            }}
          >
            <ReusableButton
              buttonText={"Cancel"}
              color="#4F5359"
              backgroundColor="#FFFFFF"
              width="50%"
              height="40px"
              onClickFunction={handleClose}
              border="1px solid #D0D5DD"
            />
            <ReusableButton
              buttonText={"Add to program"}
              width="50%"
              height="40px"
              onClickFunction={() => {}}
              backgroundColor="#2974B1"
              beforeIcon={<img src="/assets/icon-plus.svg" alt="icon" />}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 27,
              right: 22,
              cursor: "pointer",
              p: 1,
            }}
            onClick={handleClose}
          >
            <img
              src="/assets/close-icon.svg"
              alt="close"
              style={{
                width: "12px",
                height: "12px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MealLibraryAddToProgramModal;
