import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";

type Props = {
  icon: string;
  text: string;
  route: string;
  isSidebarExpanded: boolean;
};

const SidebarButton = ({ icon, text, route, isSidebarExpanded }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isOnPage = location.pathname.includes(route);

  return (
    <Box
      sx={{
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: isSidebarExpanded ? "flex-start" : "center",
        gap: isSidebarExpanded ? 1 : 0,
        padding: "8px 12px",
        cursor: "pointer",
        borderRadius: 2,
        bgcolor: isOnPage ? "#F2F5F6" : "white",
        "&:hover": {
          bgcolor: "#F2F5F6",
        },
        position: "relative",
        userSelect: "none",
      }}
      onClick={() => text !== "Activity" && navigate(route)}
    >
      <img
        alt="icon"
        src={icon}
        style={{
          filter: isOnPage
            ? `brightness(0) saturate(100%) invert(42%) sepia(66%) saturate(537%) hue-rotate(165deg) brightness(85%) contrast(97%)`
            : "none",
        }}
      />
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 22.4,
          color: isOnPage ? "#2974B1" : "black",
          pointerEvents: "none",
          overflow: "hidden",
          whiteSpace: "nowrap",
          transition: "opacity 0.3s ease",
          opacity: isSidebarExpanded ? 1 : 0,
          width: isSidebarExpanded ? "auto" : 0,
        }}
      >
        {text}
        {/* Temporary */}
        {text === "Activity" && isSidebarExpanded && (
          <img
            alt="beta-tag"
            src="/assets/beta-tag.svg"
            style={{ position: "absolute", top: 0, right: 15 }}
          />
        )}
      </Typography>
    </Box>
  );
};

export default SidebarButton;
