import { useMutation } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";

export function useResetPaswordMutation() {
  return useMutation({
    mutationFn: async (email: string) => {
      const response = await coreServiceAxios.post(
        `/api/auth/forgot-password`,
        {
          email: email,
        }
      );

      return response.data;
    },
  });
}
