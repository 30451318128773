import { ReactNode, useCallback, useMemo, useState } from "react";
import { AuthTokenContext, Token } from "./authToken";
import { useQueryClient } from "@tanstack/react-query";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../constants/auth";

const AuthTokenProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();

  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
  );

  const updateAuthToken = useCallback(
    (token: { authToken: string } | null) => {
      setAuthToken(token?.authToken ?? null);

      if (token) {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token.authToken);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
        queryClient.invalidateQueries();
      }
    },
    [queryClient]
  );

  const providerValue = useMemo((): Token => {
    return authToken
      ? {
          hasToken: true,
          tokenHeaders: { Authorization: `Bearer ${authToken}` },
          updateAuthToken,
        }
      : { hasToken: false, tokenHeaders: {}, updateAuthToken };
  }, [authToken, updateAuthToken]);

  return (
    <AuthTokenContext.Provider value={providerValue}>
      {children}
    </AuthTokenContext.Provider>
  );
};

export default AuthTokenProvider;
