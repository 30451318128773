import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../programs/widgets";
import ActiveProgram from "./widgets/ActiveProgram";
import EnrolledProgram from "./widgets/EnrolledProgram";

const MyProgramsLeftCol = () => {
  return (
    <Box>
      <Typography
        fontSize={24}
        fontWeight={600}
        color="#2974B1"
        fontFamily="Comfortaa"
      >
        Your programs
      </Typography>
      <SectionHeader title="Active" />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <ActiveProgram />
        <ActiveProgram />
        <ActiveProgram />
        <ActiveProgram />
      </Box>
      <SectionHeader title="Programs to Start" />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <EnrolledProgram />
        <EnrolledProgram />
        <EnrolledProgram />
      </Box>
    </Box>
  );
};

export default MyProgramsLeftCol;
