import { Box, Menu, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAuthToken } from "../../../auth/authToken";
import { adminRoutes } from "../../../constants/routes/admin";
import { sharedRoutes } from "../../../constants/routes/shared";
import { userRoutes } from "../../../constants/routes/user";
import { Role } from "../../../enums/auth";
import { isInRole } from "../../../utils/auth";

type UserMenuOptionProps = {
  icon: string;
  text: string;
  route?: string;
  onClick?: () => void;
};

const UserMenuOption = ({
  icon,
  text,
  route,
  onClick,
}: UserMenuOptionProps) => {
  return (
    <Box
      sx={{
        height: 40,
        display: "flex",
        alignItems: "center",
        gap: 1,
        padding: "8px 12px",
        cursor: "pointer",
        borderRadius: 2,
        "&:hover": {
          bgcolor: "#F2F5F6",
        },
        userSelect: "none",
      }}
      onClick={onClick}
    >
      <img alt="account" src={icon} />
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          color: "black",
          pointerEvents: "none",
          overflow: "hidden",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

type Props = {
  role: Role;
};

const UserMenu = ({ role }: Props) => {
  const { updateAuthToken } = useAuthToken();
  const navigate = useNavigate();
  const [profileAnchor, setProfileAnchor] = useState<null | HTMLElement>(null);

  const handleCloseProfileMenu = () => {
    setProfileAnchor(null);
  };

  return (
    <>
      <Box
        sx={{ cursor: "pointer", mr: 2 }}
        onClick={(event) => setProfileAnchor(event.currentTarget)}
      >
        <img
          alt="user"
          src={"/assets/profile-avatar-icon.svg"}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Menu
        anchorEl={profileAnchor}
        open={Boolean(profileAnchor)}
        onClose={handleCloseProfileMenu}
        MenuListProps={{
          style: {
            padding: "5px 8px",
            minWidth: 180,
          },
        }}
      >
        <UserMenuOption
          icon="/assets/account-icon.svg"
          text="Account"
          onClick={() => {
            handleCloseProfileMenu();
            navigate(sharedRoutes.accountDetails);
          }}
        />
        {isInRole(role, Role.USER) && (
          <UserMenuOption
            icon="/assets/profile-icon.svg"
            text="Profile"
            onClick={() => {
              handleCloseProfileMenu();
              navigate(userRoutes.onboarding);
            }}
          />
        )}
        {isInRole(role, Role.ADMIN) && (
          <UserMenuOption
            icon="/assets/side-settings-icon.svg"
            text="Management"
            onClick={() => {
              handleCloseProfileMenu();
              navigate(adminRoutes.userManagement);
            }}
          />
        )}
        <UserMenuOption
          icon="/assets/side-logout-icon.svg"
          text="Log out"
          onClick={() => {
            handleCloseProfileMenu();
            updateAuthToken(null);
          }}
        />
      </Menu>
    </>
  );
};

export default UserMenu;
