import { expertRoutes } from "../routes/expert";
import { userRoutes } from "../routes/user";

export const NAVBAR_HEIGHT = 64;

export const expertAndAdminNavigation = [
  {
    route: expertRoutes.home,
    text: "Home",
  },
  {
    route: expertRoutes.science,
    text: "Science",
  },

  {
    route: expertRoutes.sharedImpact,
    text: "Shared Impact",
    isBeta: true,
  },
  {
    route: expertRoutes.programs,
    text: "Programs",
  },
  {
    route: expertRoutes.programLibrary,
    text: "Program Library",
  },
  {
    route: expertRoutes.marketplace,
    text: "Marketplace",
  },
];

export const userNavigation: {
  icon: string;
  route: string;
  text: string;
  children: { route: string; icon: string; text: string }[];
}[] = [
  {
    route: userRoutes.today,
    icon: "/assets/side-today-icon.svg",
    text: "Today",
    children: [],
  },
  {
    route: userRoutes.activity,
    icon: "/assets/side-activity-icon.svg",
    text: "Activity",
    children: [],
  },
  {
    route: userRoutes.myPrograms,
    icon: "/assets/side-myprograms-icon.svg",
    text: "My Programs",
    children: [],
  },
  {
    route: "",
    icon: "/assets/side-explore-icon.svg",
    text: "Explore",
    children: [
      {
        route: userRoutes.explore.programs,
        icon: "/assets/side-myprograms-icon.svg",
        text: "Programs",
      },
      {
        route: userRoutes.explore.experts,
        icon: "/assets/side-experts-icon.svg",
        text: "Experts",
      },
    ],
  },
];
