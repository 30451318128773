import axios, {
  AxiosInstance,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from "axios";
import { NavigateFunction } from "react-router";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../constants/auth";
import { anonymousRoutes } from "../constants/routes/anonymous";
import { sharedRoutes } from "../constants/routes/shared";

const AUTH_HEADER = "Authorization";

const createAxiosInstance = (baseURL: string) => {
  const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
  });

  axiosInstance.interceptors.request.use(
    async (request: InternalAxiosRequestConfig) => {
      const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

      if (accessToken && !request.headers.skipAuth) {
        request.headers[AUTH_HEADER] = `Bearer ${accessToken}`;
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

const attachInterceptors = (
  axiosInstance: AxiosInstance,
  navigate: NavigateFunction,
  updateAuthToken:
    | ((
        token: {
          authToken: string;
        } | null
      ) => void)
    | ((
        token: {
          authToken: string;
        } | null
      ) => void),
  showSnackbar: (
    type: "success" | "error" | "info" | "warning",
    message: string,
    duration: number
  ) => void
) => {
  axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response;
    },
    (error) => {
      switch (error.response?.status) {
        case HttpStatusCode.Unauthorized:
          updateAuthToken(null);
          navigate(anonymousRoutes.login);
          break;
        case HttpStatusCode.InternalServerError:
          navigate(sharedRoutes.serverError);
          break;
        case HttpStatusCode.NotFound:
          navigate(sharedRoutes.notFound);
          break;
        default:
          break;
      }

      return Promise.reject(error);
    }
  );
};

const chatServiceAxios = createAxiosInstance(
  process.env.REACT_APP_CHAT_SERVICE_URL || ""
);

const coreServiceAxios = createAxiosInstance(
  process.env.REACT_APP_CORE_SERVICE_URL || ""
);

const openAiServiceAxios = createAxiosInstance(
  process.env.REACT_APP_OPENAI_INTEGRATION_URL || ""
);

export {
  attachInterceptors,
  chatServiceAxios,
  coreServiceAxios,
  openAiServiceAxios,
};
