import { FC } from "react";
import { theme } from "../../../config/mui-theme";
import { ProgramType } from "../../../types/programs/ProgramsType";
import { Column, Row } from "../../reusable/layout";
import VerticalBars from "../../reusable/VerticalBars";
import {
  ComfortaaLarge700,
  ComfortaaSmall400,
  ComfortaaSmall600,
  ComfortaaSmallMedium500,
} from "../TodayLabels.style";
type Props = {
  program: ProgramType;
};

const SuggestedProgramCard: FC<Props> = ({ program }) => {
  return (
    <Row
      sx={{
        border: "1px solid ",
        borderRadius: "30px",
        marginTop: 2,
        borderColor: theme.palette.primary.light,
        cursor: "pointer",
        alignItems: "center",
        height: "185px",
        justifyContent: "flex-start",
      }}
    >
      <img
        src={program.cover || "/assets/science-page-obesity-section.jpg"}
        alt="program"
        style={{
          height: "100%",
          width: "30%",
          objectFit: "cover",
          borderTopLeftRadius: "30px",
          borderBottomLeftRadius: "30px",
        }}
      />
      <VerticalBars />
      <Column
        sx={{
          alignItems: "flex-start",
          maxWidth: "60%",
          marginLeft: 3,
        }}
      >
        <ComfortaaLarge700>{program.name}</ComfortaaLarge700>
        <Row sx={{ width: "auto", marginBlock: 1 }}>
          <Row
            sx={{
              background:
                "linear-gradient(270deg, rgba(57, 146, 221, 0.3) 0%, rgba(242, 185, 253, 0.3) 100%)",
              width: "auto",
              borderRadius: 4,
              paddingBlock: 0.5,
              paddingInline: 2,
              alignItems: "center",
              marginRight: 1,
            }}
          >
            <img
              src={"/assets/ecg-heart.svg"}
              alt={""}
              style={{ marginRight: 10 }}
            />
            <ComfortaaSmallMedium500 color={theme.palette.primary.main}>
              {program.difficulty}
            </ComfortaaSmallMedium500>
          </Row>
          <Row sx={{ alignItems: "center" }}>
            <img
              src={"/assets/gray-calendar.svg"}
              alt={""}
              style={{ marginRight: 10 }}
            />
            <ComfortaaSmallMedium500>5 Weeks Total</ComfortaaSmallMedium500>
          </Row>
        </Row>
        <ComfortaaSmall600 color={theme.palette.text.secondary}>
          DESCRIPTION
        </ComfortaaSmall600>
        <ComfortaaSmall400
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 3, // Limits to 3 lines
            WebkitBoxOrient: "vertical",
          }}
        >
          {program.summary}
        </ComfortaaSmall400>
      </Column>
    </Row>
  );
};

export default SuggestedProgramCard;
