import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { uploadToTmp } from "../../../utils/fileUtils";

export const useCreateMealMutation = () => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Meal) => {
      const { file, ...rest } = data;

      const f = file
        ? {
            key: (await uploadToTmp(file)).key,
            name: file.name,
            type: file.type,
          }
        : undefined;

      const result = await coreServiceAxios.post(`api/health/meal`, {
        ...rest,
        file: f,
      });

      return result.data;
    },
    onSuccess: () => {
      showSnackbar("success", "Meal created successfully.");
      queryClient.invalidateQueries({
        queryKey: ["meals"],
      });
    },
    onError: () => {
      showSnackbar("error", "Failed to create meal.");
    },
  });
};
