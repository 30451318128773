import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";

export function useUserExerciseProgramMutation() {
  const queryClient = useQueryClient();
  const { data: me } = useUsersMeQuery();

  return useMutation({
    mutationFn: async (data: any) => {
      const response = await coreServiceAxios.post(
        `/api/users/onboarding/exerciseProgram/${me?.id}`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["api/users/onboarding"] });
    },
  });
}
