import { FC } from "react";
import { Column, Row } from "../../reusable/layout";
import {
  OpenSansMedium400,
  OpenSansMedium700,
  OpenSansSmallMedium500,
} from "../TodayLabels.style";
import { theme } from "../../../config/mui-theme";

type Props = {
  mealPlan: Meal;
  onClick?: any;
};

const SuggestedMealPLan: FC<Props> = ({ mealPlan, onClick }) => {
  return (
    <Column sx={{ width: "48%", height: 340 }}>
      <img
        src={mealPlan.cover || "/assets/science-page-obesity-section.jpg"}
        alt="mealPlan-img"
        style={{
          height: "50%",
          width: "100%",
          objectFit: "cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      />
      <Column
        sx={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          height: "60%",
        }}
      >
        <OpenSansMedium700>{mealPlan.name}</OpenSansMedium700>
        <Row
          sx={{
            justifyContent: "space-between",
            marginTop: 1,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderColor: theme.palette.primary.main,
            paddingBottom: 0.5,
          }}
        >
          <Row
            sx={{
              backgroundColor: theme.palette.primary.main,
              paddingBlock: 0,
              paddingInline: 1,
              borderRadius: 5,
              width: "auto",
            }}
          >
            <OpenSansSmallMedium500 color={theme.palette.common.white}>
              {mealPlan.type}
            </OpenSansSmallMedium500>
          </Row>
          <OpenSansSmallMedium500 color={theme.palette.text.secondary}>
            {mealPlan.calories} calories
          </OpenSansSmallMedium500>
        </Row>
        <Column>
          <OpenSansMedium400>{mealPlan.description}</OpenSansMedium400>
        </Column>
      </Column>
      <img
        onClick={onClick}
        src="/assets/arrow-down.svg"
        alt="arrow-down"
        style={{
          width: "14px",
          height: "14px",
          cursor: "pointer",
          marginTop: 15,
          alignSelf: "flex-end",
        }}
      />
    </Column>
  );
};

export default SuggestedMealPLan;
