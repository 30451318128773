import { Box, Typography } from "@mui/material";

type Props = {
  content: string;
};

const RichTextDisplay = ({ content }: Props) => {
  return (
    <Box
      sx={{
        "& ul": {
          listStyleType: "disc",
          paddingLeft: "20px",
        },
        "& ol": {
          listStyleType: "decimal",
          paddingLeft: "20px",
        },
        "& li": {
          fontSize: "16px",
          color: "black",
        },
        "& p": {
          marginBottom: "16px",
          fontSize: "16px",
          color: "black",
        },
        "& strong": {
          fontWeight: "bold",
        },
        "& em": {
          fontStyle: "italic",
        },
        "& h1": {
          fontSize: "32px",
          fontWeight: 700,
          marginBottom: "16px",
        },
        "& h2": {
          fontSize: "28px",
          fontWeight: 600,
          marginBottom: "14px",
        },
        "& h3": {
          fontSize: "24px",
          fontWeight: 500,
          marginBottom: "12px",
        },
        "& a": {
          color: "#2974B1",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
    >
      <Typography dangerouslySetInnerHTML={{ __html: content }} />
    </Box>
  );
};

export default RichTextDisplay;
