import { Box } from "@mui/material";
import TotalExpenses from "./TotalExpenses";
import { ProgramData } from "../../../../../schemas/programs/programs";
import LongevityProgramCost from "./LongevityProgramCost";

const FinancialOverview = ({ program }: { program: ProgramData }) => {
  return (
    <Box
      sx={{
        padding: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 4,
      }}
    >
      <TotalExpenses program={program} />
      <LongevityProgramCost program={program} />
    </Box>
  );
};

export default FinancialOverview;
