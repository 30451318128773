import { SectionHeader } from "../../programs/widgets";
import { Row } from "../../reusable/layout";
import SuggestedMealPLan from "../cards/SuggestedMealPlan";

const SuggestedMeals = () => {
  const meal: Meal = {
    id: 1,
    name: "Grilled Chicken Salad",
    type: "Lunch",
    dietPlan: "Mediterranean",
    description:
      "A delicious and healthy grilled chicken salad with mixed greens, tomatoes, and a light vinaigrette dressing.",
    preparationTime: 25,
    calories: 320,
    recipe:
      "1. Grill the chicken. 2. Toss the salad with greens and tomatoes. 3. Mix with vinaigrette and serve.",
    references: "https://example.com/grilled-chicken-salad",
    createdById: 1001,
    cover: "/assets/meal1.jpg",
    createdBy: { firstName: "John", lastName: "Doe" },
  };

  const meal2: Meal = {
    id: 2,
    name: "Grilled Chicken Salad with Mixed Greens, Almonds, and Olive Oil",
    type: "Dinner",
    dietPlan: "Paleo",
    description:
      "A colorful and nutritious bowl packed with quinoa, roasted veggies, avocado, and a tahini dressing.",
    preparationTime: 40,
    calories: 450,
    recipe:
      "1. Cook the quinoa. 2. Roast the veggies. 3. Assemble the bowl with quinoa, veggies, avocado, and drizzle with tahini dressing.",
    references: "https://example.com/vegan-buddha-bowl",
    createdById: 1002,
    cover: "/assets/meal2.jpg",
    createdBy: { firstName: "John", lastName: "Doe" },
  };
  return (
    <>
      <SectionHeader title="Meal Plan Suggestions For You" />
      <Row sx={{ paddingTop: "20px", justifyContent: "space-between" }}>
        <SuggestedMealPLan mealPlan={meal} />
        <SuggestedMealPLan mealPlan={meal2} />
      </Row>
    </>
  );
};

export default SuggestedMeals;
