import { Column, Row } from "../../components/reusable/layout";
import TodayContent from "../../components/today/TodayContent";
import TodaySideBar from "../../components/today/TodaySideBar";
import { ComfortaaLarge600 } from "../../components/today/TodayLabels.style";
import { theme } from "../../config/mui-theme";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";

const TodayPage = () => {
  const { data: user } = useUsersMeQuery();

  return (
    <Column sx={{ padding: 5, alignItems: "flex-start" }}>
      <ComfortaaLarge600
        color={theme.palette.primary.main}
        sx={{ marginBottom: 3 }}
      >
        Welcome, {user?.firstName}
      </ComfortaaLarge600>
      <Row>
        <TodayContent />
        <TodaySideBar />
      </Row>
    </Column>
  );
};

export default TodayPage;
