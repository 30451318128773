import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useWorkoutsQuery } from "../../../api/program-library/workout/workoutsQuery";
import { filterOptions } from "../../../constants/program-library/program-library";
import { useDebounce } from "../../../hooks/useDebounce";
import { SectionHeader } from "../../programs/widgets";
import {
  ReusableButton,
  ReusableSelect,
  ReusableTextField,
} from "../../reusable";
import { useBackdrop } from "../../reusable/LoadingSpinners/infinityBackdrop";
import WorkoutLibraryCard from "./WorkoutLibraryCard";
import WorkoutLibraryCreationModal from "./WorkoutLibraryCreationModal";
import WorkoutLibraryAddToProgramModal from "./WorkoutLibraryAddToProgramModal";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";

type Props = {
  form: any;
};

const WorkoutLibrary = ({ form }: Props) => {
  const { control, watch } = form;
  const { toggleBackdrop } = useBackdrop();

  const searchInput = watch("searchInput");
  const sort = watch("sort");
  const duration = watch("duration");
  const type = watch("type");
  const difficulty = watch("difficulty");

  const debouncedSearchInput = useDebounce(searchInput);

  const [openCreationModal, setOpenCreationModal] = useState<boolean>(false);
  const [openAddToProgramModal, setOpenAddToProgramModal] =
    useState<Workout | null>(null);

  const { workouts, isLoading, fetchNextPage } = useWorkoutsQuery({
    searchInput: debouncedSearchInput,
    duration,
    sort,
    type,
    difficulty,
  });

  const triggerRef = useInfiniteScroll({
    fetchNextPage,
    isLoading,
  });

  useEffect(() => {
    toggleBackdrop(isLoading);
  }, [isLoading, toggleBackdrop]);

  return (
    <>
      <WorkoutLibraryCreationModal
        open={openCreationModal}
        handleClose={() => setOpenCreationModal(false)}
      />
      {openAddToProgramModal && (
        <WorkoutLibraryAddToProgramModal
          workout={openAddToProgramModal}
          open={!!openAddToProgramModal}
          handleClose={() => setOpenAddToProgramModal(null)}
        />
      )}
      <Box
        sx={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          px: "16px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            mt: 2.5,
          }}
        >
          <Box
            sx={{
              width: "83%",
            }}
          >
            <ReusableTextField
              control={control}
              name="searchInput"
              inputType="text"
              placeholder="Search"
              borderRadius="8px"
              fullWidth
              startAdornment={
                <img src="/assets/search-icon.svg" alt="search" />
              }
            />
          </Box>
          <Box
            sx={{
              width: "17%",
              pt: 0.5,
            }}
          >
            <ReusableSelect
              control={control}
              borderRadius="10px"
              placeholder="Sort by"
              name="sort"
              options={filterOptions}
            />
          </Box>
          <ReusableButton
            buttonText={"Add New Workout"}
            width="25%"
            height="40px"
            beforeIcon={<img src="/assets/icon-plus.svg" alt="icon" />}
            onClickFunction={() => setOpenCreationModal(true)}
          />
        </Box>
        <SectionHeader title="All Workouts" />
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "33% 33% 33%",
            gap: 2,
            my: 2,
          }}
        >
          {workouts.length === 0 ? (
            <Typography>Nothing to show.</Typography>
          ) : (
            workouts.map((workout: Workout, ind) => (
              <WorkoutLibraryCard
                key={ind}
                workout={workout}
                handleOpenAddToProgramModal={() =>
                  setOpenAddToProgramModal(workout)
                }
              />
            ))
          )}
        </Box>
        <Box ref={triggerRef}></Box>
      </Box>
    </>
  );
};

export default WorkoutLibrary;
