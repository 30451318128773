import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useRegisterMutation } from "../../api/users/registerMutation";
import { Header, PrivacyPolicy } from "../../components/registration";
import ErrorScreen from "../../components/registration/ErrorScreen";
import { ReusableButton } from "../../components/reusable";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { type CountryType } from "../../constants/countries";
import {
  APPLICATION_TYPE,
  registerPageCustomTheme,
} from "../../constants/registration";
import {
  RegisterDataDefaultValues,
  defaultCountry,
  registrationSchema,
} from "../../schemas/user/registration";
import type { RegisterDataType } from "../../types/registration/RegisterDataType";
import PersonalInformation from "../../components/registration/PersonalInformation";

export const renderCountryOption = (props: any, option: any) => (
  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
    <img
      loading="lazy"
      width="20"
      srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
      src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
      alt=""
      onError={(e) => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.style.display = "none";
      }}
    />
    {option.label}
  </Box>
);

const RegisterPage = () => {
  const [countryDialCode, setCountryDialCode] =
    useState<CountryType>(defaultCountry);
  const [isLoading, setIsLoading] = useState(false);
  const [hasValidToken, setHasValidToken] = useState<boolean>(false);
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);

  const { mutate } = useRegisterMutation();
  const { showSnackbar } = useSnackbar();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const code = params.get("code");

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: RegisterDataDefaultValues,
    resolver: yupResolver(registrationSchema),
  });

  const verifyInvitationToken = useCallback(
    async (token: string) => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_CORE_SERVICE_URL}/api/register/verifyinvitationtoken`,
          {
            body: JSON.stringify({ token }),
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 200) {
          const responseData = await res.json();
          if (!responseData.decoded.data.email) {
            setShowErrorScreen(true);
            return;
          }
          setValue("email", responseData.decoded.data.email);
          setHasValidToken(true);
        } else {
          setShowErrorScreen(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setShowErrorScreen(true);
      }
    },
    [setValue]
  );

  const onSubmit = async (value: RegisterDataType) => {
    if (isLoading) return;
    setIsLoading(true);
    const {
      confirmPassword,
      country,
      phoneNumber,
      countryDialCodeNumber,
      ...rest
    } = value;

    const object = {
      ...rest,
      country: value.country.label,
      countryDialCodeNumber: `${countryDialCode.phone}`,
      phoneNumber,
      token: code ?? "",
      applicationType: APPLICATION_TYPE,
    };
    try {
      mutate(object);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("error", "Something went wrong.", 3000);
    }
  };

  useEffect(() => {
    if (code) {
      verifyInvitationToken(code);
    } else {
      setShowErrorScreen(true);
    }
  }, [code, verifyInvitationToken]);

  return (
    <ThemeProvider theme={registerPageCustomTheme}>
      {hasValidToken && (
        <Container component="main">
          <CssBaseline />
          <Header />
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              sx={{ display: "flex", flexDirection: "column", mt: 1 }}
            >
              <PersonalInformation
                control={control}
                countryDialCode={countryDialCode}
                setCountryDialCode={setCountryDialCode}
              />
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <ReusableButton
                  onClickFunction={handleSubmit(onSubmit)}
                  buttonText="Continue"
                  color="#ffff"
                  backgroundColor="#2974B1"
                  width="50%"
                  isLoading={isLoading}
                />
              </Box>
            </Box>
          </Box>
          <PrivacyPolicy />
        </Container>
      )}
      {showErrorScreen && <ErrorScreen />}
    </ThemeProvider>
  );
};

export default RegisterPage;
