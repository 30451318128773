import { random } from "lodash";
import { useSuggestedWorkoutsQuery } from "../../../api/program-library/workout/workoutsQuery";
import { SectionHeader } from "../../programs/widgets";
import { Row } from "../../reusable/layout";
import SuggestedWorkoutCard from "../cards/SuggestedWorkoutcard";

const SuggestedWorkouts = () => {
  var workouts: Array<Workout> = useSuggestedWorkoutsQuery();
  if (!workouts) return null;
  workouts = workouts.slice(0, 2).map((w: Workout) => {
    return {
      ...w,
      cover: "/assets/science-page-obesity-section.jpg",
      participants: random(150),
    };
  });
  return (
    <>
      <SectionHeader title="Workout Suggestions For You" />
      <Row sx={{ paddingTop: "20px", justifyContent: "space-between" }}>
        {workouts.map((workout: Workout) => {
          return <SuggestedWorkoutCard workout={workout} />;
        })}
      </Row>
    </>
  );
};

export default SuggestedWorkouts;
