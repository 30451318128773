import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../config/axios-instance";

interface Data {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zipCode: string;
  username: string;
  phoneNumber: string;
  countryDialCodeNumber: string;
  password: string;
  interestSource: string;
  token: string;
}

export function useRegisterMutation() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (data: Data) => {
      const response = await coreServiceAxios.post<Response>(
        `api/register`,
        data
      );

      return response.data;
    },
    onSuccess: (response, variables) => {
      navigate("/successs-register", { state: { email: variables?.email } });
      showSnackbar(
        "success",
        "Please check email to verify your account.",
        5000,
        {
          vertical: "bottom",
          horizontal: "center",
        }
      );
    },
    onError: (error) => {
      showSnackbar("error", error.message.replace(/^"|"$/g, ""), 3000, {
        vertical: "bottom",
        horizontal: "center",
      });
    },
  });
}
