import { Box, Typography } from "@mui/material";
import VerticalBars from "../../reusable/VerticalBars";

const DifficultyTag = () => {
  return (
    <Box
      sx={{
        padding: "3px 12px",
        borderRadius: "12px",
        background: "linear-gradient(90deg,#F2B9FD4D, #3992DD4D)",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <img alt="heart" src="/assets/my-programs/ep-heart-icon.svg" />
      <Typography fontSize={14} color="#2974B1">
        Beginner
      </Typography>
    </Box>
  );
};

const EnrolledProgram = () => {
  return (
    <Box
      sx={{
        height: 169,
        display: "flex",
        alignItems: "center",
        border: "1px solid #E4E7EC",
        borderRadius: 5,
      }}
    >
      <Box
        sx={{
          width: 180,
          height: 169,
          flexShrink: 0,
          overflow: "hidden",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <img
          alt="program"
          src="/assets/my-programs/ep-placeholder1.png"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <VerticalBars />
      <Box
        sx={{
          py: 1.25,
          ml: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "80%",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          color="black"
          fontFamily="Comfortaa"
        >
          Menopause
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <DifficultyTag />
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <img
              alt="calendar"
              src="/assets/my-programs/ep-calendar-icon.svg"
            />
            <Typography fontSize={14} color="#615959">
              5 Weeks Total
            </Typography>
          </Box>
        </Box>
        <Typography fontSize={12} fontWeight={600} color="#7D8189">
          DESCRIPTION
        </Typography>
        <Typography fontSize={12} fontWeight={400} color="#000000" width={360}>
          Redefining menopause care with innovative programs designed to
          optimize women’s health.
        </Typography>
      </Box>
    </Box>
  );
};

export default EnrolledProgram;
