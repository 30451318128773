import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useAuthToken } from "../../auth/authToken";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../config/axios-instance";

interface Credentials {
  identifier: string;
  password: string;
}

interface Response {
  jwt: string;
  tenant: {
    id: number;
    name: string;
  };
}

export function useLogInMutation() {
  const { updateAuthToken } = useAuthToken();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (credentials: Credentials) => {
      const response = await coreServiceAxios.post<Response>(
        `/api/login`,
        credentials
      );

      return response.data;
    },
    onSuccess: (response) => {
      const token = {
        authToken: response.jwt,
      };
      updateAuthToken(token);
      navigate("/");
    },
    onError: (error) => {
      showSnackbar("error", error.message.replace(/^"|"$/g, ""), 3000, {
        vertical: "bottom",
        horizontal: "center",
      });
    },
  });
}
