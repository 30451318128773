import { Box, Typography } from "@mui/material";
import { mealLibraryFilterOptions } from "../../../constants/program-library/meal-library";
import FilterChecklist from "../../marketplace/FilterChecklist";
import ReusableSlider from "../../reusable/Form/ReusableSlider";

type Props = {
  form: any;
};

const MealLibraryFilters = ({ form }: Props) => {
  const { control } = form;
  const preparationTime = 240;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "20%",
        px: "16px",
        gap: 2,
      }}
    >
      <Typography
        fontFamily="Open Sans"
        fontSize="16px"
        fontWeight={600}
        sx={{ paddingLeft: "15px" }}
      >
        Filter by
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FilterChecklist
          name="dietPlan"
          control={control}
          options={mealLibraryFilterOptions.dietType}
          label="Diet Type"
        />
        <ReusableSlider
          name="preparationTime"
          control={control}
          maxValue={preparationTime}
          defaultValue={preparationTime}
          label="Preparation Time"
          minLabel="0 min"
          maxLabel={`${preparationTime} min`}
          placeholderText="Choose the preparation time"
        />
        <FilterChecklist
          name="mealType"
          control={control}
          options={mealLibraryFilterOptions.mealType}
          label="Meal Type"
        />
      </Box>
    </Box>
  );
};

export default MealLibraryFilters;
