import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCreateWorkoutMutation } from "../../../api/program-library/workout/createWorkoutMutation";
import { workoutSchema } from "../../../schemas/program-library/workout";
import { ReusableButton } from "../../reusable";
import { useBackdrop } from "../../reusable/LoadingSpinners/infinityBackdrop";
import WorkoutForm from "../widgets/WorkoutForm";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const WorkoutLibraryCreationModal = ({ open, handleClose }: Props) => {
  const { mutate: createWorkoutMutation } = useCreateWorkoutMutation();
  const { toggleBackdrop } = useBackdrop();

  const form = useForm({
    resolver: yupResolver(workoutSchema),
  });

  const onSubmit = (data: any) => {
    toggleBackdrop(true);
    createWorkoutMutation(data, {
      onSettled: () => {
        toggleBackdrop(false);
        closeModal();
      },
    });
  };

  const closeModal = () => {
    form.reset();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        closeModal();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          boxShadow: 5,
          outline: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "10px",
          padding: "15px 25px",
          position: "relative",
          width: 900,
          maxHeight: "98vh",
          overflowY: "auto",
          margin: "auto",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          fontFamily="Comfortaa"
          color="#2974B1"
          alignSelf="flex-start"
        >
          Create new Workout
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={400}
          mb={2}
          alignSelf="flex-start"
        >
          Add a new workout to library for easy access later
        </Typography>

        <WorkoutForm form={form} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mt: 8,
          }}
        >
          <ReusableButton
            buttonText={"Cancel"}
            color="#4F5359"
            backgroundColor="#FFFFFF"
            width="30%"
            border="1px solid #D0D5DD"
            onClickFunction={closeModal}
          />
          <ReusableButton
            buttonText={"Save"}
            width="30%"
            backgroundColor="#2974B1"
            onClickFunction={form.handleSubmit(onSubmit)}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 27,
            right: 22,
            cursor: "pointer",
            p: 1,
          }}
          onClick={closeModal}
        >
          <img
            src="/assets/close-icon.svg"
            alt="close"
            style={{
              width: "12px",
              height: "12px",
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default WorkoutLibraryCreationModal;
