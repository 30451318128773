import { Column } from "../reusable/layout";
import SuggestedMeals from "./sections/SuggestedMeals";
import SuggestedPrograms from "./sections/SuggestedPrograms";
import SuggestedWorkouts from "./sections/SuggestedWorkouts";
import WelcomeSection from "./sections/WelcomeSection";

const TodayContent = () => {
  return (
    <Column sx={{ width: "65%" }}>
      <WelcomeSection />
      <SuggestedPrograms />
      <SuggestedWorkouts />
      <SuggestedMeals />
    </Column>
  );
};

export default TodayContent;
