import { Container } from "@mui/material";
import { useState } from "react";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import AccountDetailsHeader from "../../components/profile/account-details/AccountDetailsHeader";
import ContactDetailsForm from "../../components/profile/account-details/ContactDetailsForm";
import PrivacyForm from "../../components/profile/account-details/PrivacyForm";
import { AccountDetailsTabs } from "../../constants/account-details";

const AccountDetailsPage = () => {
  const { data: user } = useUsersMeQuery();
  const [selectedTab, setSelectedTab] = useState<AccountDetailsTabs>(
    AccountDetailsTabs.ContactDetails
  );

  return (
    <Container>
      <AccountDetailsHeader
        username={user?.username}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === AccountDetailsTabs.ContactDetails && (
        <ContactDetailsForm user={user} />
      )}
      {selectedTab === AccountDetailsTabs.Privacy && <PrivacyForm />}
    </Container>
  );
};

export default AccountDetailsPage;
