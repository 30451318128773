import {
  ChevronLeftRounded as CollapseIcon,
  ChevronRightRounded as ExpandIcon,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { userNavigation } from "../../../constants/navigation/navigation";
import SidebarButton from "./SidebarButton";
import SidebarExpandableButton from "./SidebarExpandableButton";

const Sidebar = () => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleSidebar = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: expanded ? 205 : 72,
        transition: "width 0.3s ease",
        borderRight: "1px solid #F2F4F7",
        position: "relative",
        "&:hover .toggle-icon": {
          opacity: 1,
        },
        userSelect: "none",
      }}
    >
      <IconButton
        disableRipple
        onClick={toggleSidebar}
        className="toggle-icon"
        sx={{
          width: 25,
          height: 25,
          position: "absolute",
          top: 25,
          right: -12.5,
          border: "1px solid #F2F4F7",
          bgcolor: "white",
          p: 0,
          opacity: 0,
          transition: "opacity 0.3s ease",
        }}
      >
        {expanded ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>

      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: expanded ? "32px 16px" : "32px 8px",
            gap: 1,
          }}
        >
          {userNavigation.map((el, ind) =>
            el.children.length > 0 ? (
              <SidebarExpandableButton
                key={ind}
                icon={el.icon}
                text={el.text}
                children={el.children}
                isSidebarExpanded={expanded}
              />
            ) : (
              <SidebarButton
                key={ind}
                route={el.route}
                icon={el.icon}
                text={el.text}
                isSidebarExpanded={expanded}
              />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
