import { Box, Typography } from "@mui/material";
import { useState } from "react";
import HeaderText from "../../components/landing/HeaderText";
import SubHeaderSection from "../../components/landing/SubHeaderSection";
import ContentSection from "../../components/landing/ContentSection";
import ScienceContentSection from "../../components/science/ScienceContentSection";

const SciencePage = () => {
  const [isExploreSectionExpanded, setIsExploreSectionExpanded] =
    useState(false);
  const handleExploreSectionReadMore = () => {
    setIsExploreSectionExpanded(!isExploreSectionExpanded);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "745px",
          backgroundImage: "url(/assets/header-image.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 24px 24px",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "8px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <HeaderText text="Science" />
        </Box>
      </Box>
      <Box sx={{ maxWidth: "70%", mx: "auto" }}>
        <SubHeaderSection text="Fresh Earth is driving a data-powered health revolution by uniting experts, participants, suppliers, and investors to create scalable, cost-effective programs. By integrating food, medicine, and advanced AI, the platform fosters a collaborative, circular economy that delivers innovative, sustainable health solutions globally." />
      </Box>
      <Box>
        {/* Health Program Section */}
        <ContentSection
          title="Explore"
          bodyText="Explore each category to see how Fresh Earth is driving the next wave of health innovation. Join us in our mission to create a healthier, longer-lived society through the power of science, collaboration, and verified data."
          expandedText={
            <>
              <Typography>
                Fresh Earth is pioneering a transformative approach to health,
                where data and collaboration fuel a revolution in well-being. By
                bringing together experts, participants, and investors, we
                create accessible, cost-effective solutions that address the
                world’s most pressing health challenges.
              </Typography>
              <br></br>
              <Typography>
                Through the integration of food, medicine, and supplements,
                combined with advanced AI and a unified data approach, Fresh
                Earth is building a scalable platform that redefines health
                within a collaborative, circular economy. This movement
                leverages peer-reviewed science and verified diagnostics to
                empower individuals and communities, driving rapid innovation
                and delivering sustainable health outcomes for society.
              </Typography>
            </>
          }
          isExpanded={isExploreSectionExpanded}
          onToggleExpand={handleExploreSectionReadMore}
          buttonText="Read More"
          imagePosition="right"
          imageSrc="/assets/science-page-explore-section.jpg"
          imageAlt="science-page-explore-section"
          backgroundColor="rgba(249, 250, 251, 1)"
          buttonColor="rgba(41, 116, 177, 1)"
          hoverColor="rgba(20, 87, 144, 1)"
          hoverTextColor="rgba(255, 255, 255, 1)"
        />
      </Box>
      <Box>
        <ScienceContentSection
          title="Longevity"
          text="Extending the human lifespan with groundbreaking research and scientifically-backed health programs."
          onClick={() => console.log("Button clicked")}
          imageSrc="/assets/header-image.jpg"
          imageAlt="science-page-longevity"
        />
        <ScienceContentSection
          title="Diabetes"
          text="Transforming diabetes management with personalized, evidence-based solutions."
          onClick={() => console.log("Button clicked")}
          imageSrc="/assets/science-page-diabetes-section.jpg"
          imageAlt="science-page-diabetes-section"
        />
        <ScienceContentSection
          title="Obesity"
          text="Addressing obesity with targeted interventions grounded in robust scientific research."
          onClick={() => console.log("Button clicked")}
          imageSrc="/assets/science-page-obesity-section.jpg"
          imageAlt="science-page-obesity-section"
        />
        <ScienceContentSection
          title="Menopause"
          text="Redefining menopause care with innovative programs designed to optimize women’s health."
          onClick={() => console.log("Button clicked")}
          imageSrc="/assets/science-page-menopause-section.jpg"
          imageAlt="science-page-menopause-section"
        />
        <ScienceContentSection
          title="Cognitive Health"
          text="Enhancing cognitive function and preventing decline with advanced, data-driven strategies."
          onClick={() => console.log("Button clicked")}
          imageSrc="/assets/science-page-cognitive-health-section.jpg"
          imageAlt="science-page-cognitive-health-section"
        />
      </Box>
    </Box>
  );
};

export default SciencePage;
