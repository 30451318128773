export const expertRoutes = {
  accountDetails: "/profile/account-details",
  programs: "/programs",
  createProgram: "/program/create",
  editProgram: (id: string | number) => `/program/edit/${id}`,
  programOverview: (id: string | number) => `/program/${id}`,
  marketplace: "/marketplace",
  programLibrary: "/program-library",
  home: "/home",
  science: "/science",
  sharedImpact: "/shared-impact",
};
