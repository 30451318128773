import { FC } from "react";
import { Column, Row } from "../../reusable/layout";
import {
  OpenSansMedium700,
  OpenSansSmallMedium400,
} from "../TodayLabels.style";
import { theme } from "../../../config/mui-theme";
import { LinearProgress } from "@mui/material";

type Props = {
  workout: Workout;
};
const SuggestedWorkoutCard: FC<Props> = ({ workout }) => {
  // TODO: Questions:
  // IS this single workout or group of workouts and how
  // Check how are we calculating classes
  // Should this be started  workouts if this is a new user -

  const totalWorkouts = 30;
  const completedWorkouts = 21;
  const completedPercentage = (21 / 30) * 100;

  return (
    <Column sx={{ width: "48%", height: 310 }}>
      <img
        src={workout.cover || "/assets/science-page-obesity-section.jpg"}
        alt="workout-img"
        style={{
          height: "50%",
          width: "100%",
          objectFit: "cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      />
      <Column
        sx={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "80%",
          marginTop: 1.5,
          height: "60%",
        }}
      >
        <OpenSansMedium700>{workout.name}</OpenSansMedium700>
        <Row sx={{ justifyContent: "space-between", marginBlock: 1 }}>
          <OpenSansSmallMedium400 color={theme.palette.primary.main}>
            {workout.participants} participants
          </OpenSansSmallMedium400>
          <Row sx={{ alignItems: "center", width: "40%" }}>
            <img
              src="/assets/play-icon.svg"
              alt="play-icon"
              style={{ marginRight: 8 }}
            />
            <OpenSansSmallMedium400 color={theme.palette.primary.main}>
              {workout.duration} classes
            </OpenSansSmallMedium400>
          </Row>
        </Row>
        <Column sx={{ alignItems: "flex-start" }}>
          <OpenSansSmallMedium400 color={theme.palette.text.secondary}>
            {completedWorkouts}/{totalWorkouts}
          </OpenSansSmallMedium400>
          <LinearProgress
            variant="determinate"
            value={completedPercentage}
            sx={{ width: "100%", height: 6, marginTop: 1, borderRadius: "5px" }}
          />
        </Column>
      </Column>
    </Column>
  );
};

export default SuggestedWorkoutCard;
