import { theme } from "../../../config/mui-theme";
import { Column } from "../../reusable/layout";

const SideBarCard = () => {
  return (
    <Column
      sx={{
        borderRadius: 5,
        borderWidth: 1,
        borderColor: theme.palette.background.paper,
        height: 200, // change to auto
        marginBlock: 1,
      }}
    >
      <p> card</p>
    </Column>
  );
};

export default SideBarCard;
