import { Avatar, Box, Typography } from "@mui/material";
import { ReusableButton } from "../reusable";

const MyProgramsRightCol = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: 252,
          border: "1px solid #E4E7EC",
          borderRadius: 2,
        }}
      ></Box>
      <Box
        sx={{ height: 199, border: "1px solid #E4E7EC", borderRadius: 2, p: 2 }}
      >
        <Typography
          fontSize={16}
          fontWeight={700}
          color="#475367"
          fontFamily="Comfortaa"
        >
          Experts
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 2 }}>
          {Array.from({ length: 9 }).map((_, index) => (
            <Avatar
              key={index}
              src="/assets/my-programs/expert-avatar-placeholder.png"
            />
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            cursor: "pointer",
          }}
        >
          <Typography fontSize={14} fontWeight={600} color="#2974B1">
            Find more Experts
          </Typography>
          <img alt="more" src="/assets/my-programs/more-icon.svg" />
        </Box>
      </Box>
      <Box
        sx={{
          height: 187,
          borderRadius: 2,
          background: "linear-gradient(90deg, #F2B9FD 0%, #3992DD 100%)",
          py: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={600}
          color="white"
          fontFamily="Comfortaa"
        >
          Recommended program category
        </Typography>
        <Typography fontSize={23} fontWeight={700} color="white">
          Longevity
        </Typography>
        <ReusableButton
          buttonText={"Browse programs"}
          width="170px"
          height="30px"
          afterIcon={
            <img alt="more" src="/assets/my-programs/more-icon-white.svg" />
          }
          onClickFunction={() => {}}
        />
      </Box>
      <Box
        sx={{
          height: 197,
          border: "1px solid #E4E7EC",
          borderRadius: 2,
        }}
      ></Box>
    </Box>
  );
};

export default MyProgramsRightCol;
