import { Box } from "@mui/material";
import { workoutLibFilterOptions } from "../../../constants/program-library/workout-library";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import ReusableVideoUpload from "../../reusable/Form/ReusableVideoUpload";
import ReusableTagInputList from "../../reusable/Form/ReusableTagInputList";

type Props = {
  form: any;
};

const WorkoutForm = ({ form }: Props) => {
  const { control } = form;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1,
      }}
    >
      <ReusableTextField
        control={control}
        label="Workout Name"
        name="name"
        inputType="text"
        placeholder="Workout name"
        borderRadius="8px"
        fullWidth
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <ReusableTextField
          control={control}
          label="Duration"
          name="duration"
          inputType="number"
          placeholder="Enter duration time"
          borderRadius="8px"
          fullWidth
        />
        <ReusableSelect
          control={control}
          label="Workout Type"
          name="type"
          placeholder="Select workout type"
          borderRadius="8px"
          options={workoutLibFilterOptions.type}
        />
        <ReusableSelect
          control={control}
          label="Difficulty Level"
          name="difficulty"
          placeholder="Select difficulty level"
          borderRadius="8px"
          options={workoutLibFilterOptions.difficulty}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ width: "50%", height: "max-content" }}>
          <ReusableVideoUpload form={form} name="image" />
        </Box>
        <Box sx={{ width: "50%" }}>
          <ReusableTagInputList
            control={control}
            label="Equipment"
            name="equipment"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutForm;
