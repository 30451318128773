import { Dayjs } from "dayjs";
import dayjs from "dayjs";

export const transfromToEvents = (data: Dayjs[]) => {
  return data?.map((day) => {
    return {
      title: "Exercise",
      start: dayjs(day).toDate(),
      end: dayjs(day).toDate(),
    };
  });
};

const getDaySuffix = (day: number) => {
  if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

// Function to format the DayJS object
export const formatCustomDate = (date: Dayjs) => {
  const day = date.date();
  const month = date.format("MMM");
  const suffix = getDaySuffix(day);

  return `${month} ${day}${suffix}`;
};

export const findEarliest = (programEvents: Dayjs[]) => {
  if (programEvents.length === 0) {
    return null;
  }
  return programEvents.reduce((earliest: Dayjs, current: Dayjs) => {
    return current.isBefore(earliest) ? current : earliest;
  });
};

interface DateRange {
  start: Dayjs;
  end: Dayjs;
}

export const mergeConsecutiveDates = (dates: Dayjs[]) => {
  const sortedDates = dates.sort((a, b) => (a.isBefore(b) ? -1 : 1));

  const dateRanges: DateRange[] = [];

  let currentRange: DateRange | null = null;

  for (let i = 0; i < sortedDates.length; i++) {
    const currentDate = sortedDates[i];

    if (currentRange === null) {
      currentRange = { start: currentDate, end: currentDate.add(1, "day") };
    } else if (currentDate.isSame(currentRange.end, "day")) {
      currentRange.end = currentDate.add(1, "day");
    } else {
      dateRanges.push(currentRange);
      currentRange = { start: currentDate, end: currentDate.add(1, "day") };
    }
  }

  if (currentRange !== null) {
    dateRanges.push(currentRange);
  }
  return dateRanges?.map((day) => {
    return {
      title: "Exercise",
      start: dayjs(day.start).toDate(),
      end: dayjs(day.end).toDate(),
    };
  });
};
