import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { APPLICATION_TYPE } from "../../constants/registration";
import { TenantRoles } from "../../types/user/tenantRoles";

export function useAllTenantsRolesQuery() {
  const { hasToken } = useAuthToken();

  return useQuery({
    queryKey: ["/tenantsRoles/all"],
    queryFn: async () => {
      const response = await coreServiceAxios.post<TenantRoles>(
        `/api/tenants/roles`,
        {
          applicationType: APPLICATION_TYPE,
        }
      );
      return response.data;
    },
    enabled: hasToken,
  });
}
