import { useInfiniteQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";
import { mapOrderFilter } from "../../../utils/filters";
import { getFile } from "../../../utils/fileUtils";

type Props = {
  searchInput: string;
  sort: SortFilter;
  preparationTime: number;
  dietPlan: DietPlan[];
  mealType: MealType[];
};

const fetchMeals = async ({
  pageParam = 1,
  queryKey,
}: {
  pageParam: number;
  queryKey: any;
}) => {
  const [, searchInput, sort, preparationTime, dietPlan, mealType] = queryKey;

  const filters = [
    searchInput ? `searchInput=${searchInput}` : null,
    sort ? `sort=${mapOrderFilter(sort)}` : null,
    preparationTime ? `preparationTime=${preparationTime}` : null,
    dietPlan.length > 0 ? `dietPlan=${dietPlan.join(",")}` : null,
    mealType.length > 0 ? `mealType=${mealType.join(",")}` : null,
  ]
    .filter(Boolean)
    .join("&");

  const url = `/api/health/meal?page=${pageParam}&${filters}`;

  const response = await coreServiceAxios.get(url);

  const data = await Promise.all(
    response.data?.map(async (d: any) => {
      const { fileInfo, ...rest } = d;
      const file = await getFile(fileInfo);

      return {
        ...rest,
        file: file,
      };
    })
  );

  return data;
};

export const useMealsQuery = ({
  searchInput,
  sort,
  preparationTime,
  dietPlan,
  mealType,
}: Props) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["meals", searchInput, sort, preparationTime, dietPlan, mealType],
    queryFn: fetchMeals,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length > 0) {
        return allPages.length + 1;
      }
      return undefined;
    },
    initialPageParam: 1,
  });

  const meals = data?.pages.flat() || [];

  return {
    meals,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  };
};
