import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../config/axios-instance";
import { ProgramData } from "../../schemas/programs/programs";

export const useEditProgramMutation = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: ProgramData }) => {
      const formData = new FormData();
      formData.append("name", data.programName ?? "");
      formData.append("difficulty", data.difficulty ?? "");
      data.supplements
        ?.filter((s) => s.id)
        ?.forEach((supplement, index) => {
          formData.append(
            `supplements[${index}][id]`,
            supplement.id?.toString() ?? ""
          );
          formData.append(`supplements[${index}][name]`, supplement.name ?? "");
          formData.append(
            `supplements[${index}][usage]`,
            supplement.usage ?? ""
          );
          formData.append(
            `supplements[${index}][cost]`,
            supplement.cost?.toString() ?? ""
          );
          formData.append(
            `supplements[${index}][benefits]`,
            supplement.benefits ?? ""
          );
        });
      data.testingProtocols
        ?.filter((tp) => tp.id)
        .forEach((protocol, index) => {
          formData.append(
            `testingProtocols[${index}][id]`,
            protocol.id?.toString() ?? ""
          );
          formData.append(
            `testingProtocols[${index}][name]`,
            protocol.name ?? ""
          );
          formData.append(
            `testingProtocols[${index}][cost]`,
            protocol.cost?.toString() ?? ""
          );
          formData.append(
            `testingProtocols[${index}][note]`,
            protocol.note ?? ""
          );
        });
      formData.append("mealPlan", data.mealPlan);
      formData.append("category", data.category ?? "");
      formData.append("objectives", data.objectives ?? "");
      formData.append("summary", data.summary ?? "");

      formData.append("workoutPlan", data.workoutRegime.workoutPlan ?? "");
      data.workoutRegime.days?.forEach((day, index) => {
        formData.append(`workoutDays[${index}][date]`, day?.toString() ?? "");
      });

      const response = await coreServiceAxios.post(
        `/api/health/program/edit/${id}`,
        formData
      );

      return response.data;
    },
    onSuccess: (variable) => {
      queryClient.invalidateQueries({
        queryKey: [`/healthProgram/${variable.id}`],
      });
      queryClient.invalidateQueries({
        queryKey: ["api/healthProgram"],
      });
      showSnackbar(
        "success",
        "Your program details are successfully saved.",
        3000
      );
    },
  });
};
