import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";

interface InviteUserData {
  email: string;
  industry: string;
  industryType: string;
  industrySubType: string;
  tenant: string;
}

export function useInviteUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: InviteUserData) => {
      const response = await coreServiceAxios.post(
        `/api/sendinvitationemail`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["/users/all"] });
    },
  });
}
