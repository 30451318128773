import React from "react";
import { Box, useTheme } from "@mui/material";
import { Row } from "./layout";

const Bar = ({ color }: { color: string }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "3.7px",
        backgroundColor: color,
        marginRight: "3px",
      }}
    />
  );
};

const VerticalBars = () => {
  const theme = useTheme();

  return (
    <Row sx={{ width: "auto", height: "100%" }}>
      <Bar color={theme.palette.secondary.main} />
      <Bar color={theme.palette.primary.main} />
      <Bar color={theme.palette.secondary.dark} />
    </Row>
  );
};

export default VerticalBars;
