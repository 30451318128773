import { Box, Typography } from "@mui/material";
import React from "react";
import ColoredBars from "../../reusable/ColoredBars";

type Props = {
  title: string;
  subtitle?: string;
};

const SectionHeader: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <ColoredBars />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #E4E4E4",
          width: "100%",
          paddingLeft: "12px",
          paddingRight: "20px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "26px" }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#667185",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionHeader;
