import { Box } from "@mui/material";
import { Column } from "./layout";
import { theme } from "../../config/mui-theme";

const Bar = ({ color }: { color: string }) => {
  return (
    <Box
      sx={{
        height: "5px",
        width: "36px",
        backgroundColor: color,
        marginBlock: "2px",
      }}
    />
  );
};

const ColoredBars = () => {
  return (
    <Column sx={{ width: "auto" }}>
      <Bar color={theme.palette.primary.main} />
      <Bar color={theme.palette.secondary.main} />
      <Bar color={theme.palette.secondary.dark} />
    </Column>
  );
};

export default ColoredBars;
