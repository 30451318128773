import { Navigate, Route } from "react-router";
import { anonymousRoutes } from "../constants/routes/anonymous";
import {
  LogInPage,
  RegisterPage,
  RegisterSuccessPage,
  ResetPassword,
} from "../pages";

export const useAnonymousRoutes = () => {
  return (
    <>
      <Route path={anonymousRoutes.login} element={<LogInPage />} />
      <Route path={anonymousRoutes.resetPassword} element={<ResetPassword />} />
      <Route path={anonymousRoutes.register} element={<RegisterPage />} />
      <Route
        path={anonymousRoutes.successRequest}
        element={<RegisterSuccessPage />}
      />

      <Route
        path="*"
        element={<Navigate replace to={anonymousRoutes.login} />}
      />
    </>
  );
};
