import {
  KeyboardArrowDownRounded as CollapseUpIcon,
  KeyboardArrowUp as ExpandBelowIcon,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import SidebarButton from "./SidebarButton";

type Props = {
  icon: string;
  text: string;
  children: {
    route: string;
    icon: string;
    text: string;
  }[];
  isSidebarExpanded: boolean;
};

const SidebarExpandableButton = ({
  icon,
  text,
  children,
  isSidebarExpanded,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    if (isSidebarExpanded) {
      setIsExpanded(!isExpanded);
    }
  };

  const isOnPage = children.some((child) =>
    location.pathname.includes(child.route)
  );

  return (
    <>
      <Box
        onClick={handleToggle}
        sx={{
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: isSidebarExpanded ? "flex-start" : "center",
          gap: isSidebarExpanded ? 1 : 0,
          padding: "8px 12px",
          cursor: "pointer",
          borderRadius: 2,
          bgcolor: isOnPage ? "#F2F5F6" : "white",
          "&:hover": {
            bgcolor: "#F2F5F6",
          },
        }}
      >
        <img
          alt="icon"
          src={icon}
          style={{
            filter: isOnPage
              ? `brightness(0) saturate(100%) invert(42%) sepia(66%) saturate(537%) hue-rotate(165deg) brightness(85%) contrast(97%)`
              : "none",
          }}
        />
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 22.4,
            color: isOnPage ? "#2974B1" : "black",
            pointerEvents: "none",
            overflow: "hidden",
            whiteSpace: "nowrap",
            transition: "opacity 0.3s ease",
            opacity: isSidebarExpanded ? 1 : 0,
            width: isSidebarExpanded ? "auto" : 0,
          }}
        >
          {text}
        </Typography>
        {isSidebarExpanded && (
          <Box sx={{ ml: "auto", color: "#7D8189" }}>
            {isExpanded ? <ExpandBelowIcon /> : <CollapseUpIcon />}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          ml: isSidebarExpanded ? 2.5 : 0,
          pl: isSidebarExpanded ? 0.5 : 0,
          borderLeft: isSidebarExpanded ? "2px solid #ADC3D0" : "none",
          overflow: "hidden",
          height:
            isExpanded && isSidebarExpanded ? children.length * 40 + 10 : 0,
          transition: "height 0.3s ease, margin-left 0.3s ease",
        }}
      >
        {children.map((el, ind) => (
          <SidebarButton
            key={ind}
            route={el.route}
            icon={el.icon}
            text={el.text}
            isSidebarExpanded={isSidebarExpanded}
          />
        ))}
      </Box>

      {!isSidebarExpanded && (
        <Box
          sx={{
            position: "relative",
            py: 0.5,
            "&::before, &::after": {
              content: '""',
              position: "absolute",
              left: "7px",
              right: "7px",
              height: "1px",
              backgroundColor: "#BFDFFB",
            },
            "&::before": {
              top: 0,
            },
            "&::after": {
              bottom: 0,
            },
          }}
        >
          {children.map((el, ind) => (
            <SidebarButton
              key={ind}
              route={el.route}
              icon={el.icon}
              text={el.text}
              isSidebarExpanded={isSidebarExpanded}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default SidebarExpandableButton;
