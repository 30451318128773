import { ProgramType } from "../../../types/programs/ProgramsType";
import { SectionHeader } from "../../programs/widgets";
import { Column } from "../../reusable/layout";
import SuggestedProgramCard from "../cards/SuggestedProgramCard";

const SuggestedPrograms = () => {
  const program: ProgramType = {
    id: 1,
    category: "asd",
    cover: "/assets/science-page-obesity-section.jpg",
    difficulty: "Beginner",
    name: "Longevity",
    objectives: "",
    summary:
      "The platform’s first initiative, the Longevity Program, provides a verifiable and cost-effective solution for enhancing and validating human longevity.The platform’s first initiative, the Longevity Program, provides a verifiable and cost-effective solution for enhancing and validating human longevity.The platform’s first initiative, the Longevity Program, provides a verifiable and cost-effective solution for enhancing and validating human longevity.The platform’s first initiative, the Longevity Program, provides a verifiable and cost-effective solution for enhancing and validating human longevity.",
    workoutPlan: "",
    supplements: [],
    testingProtocols: [],
  };
  return (
    <Column>
      <SectionHeader title="Program Suggestions For You" />
      <SuggestedProgramCard program={program} />
      <SuggestedProgramCard program={program} />
    </Column>
  );
};

export default SuggestedPrograms;
