import CancelIcon from "@mui/icons-material/Cancel";
import { Box, FormControl, Typography } from "@mui/material";
import { ChangeEvent, DragEvent, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useObjectURL } from "../../../hooks/object-url";

interface Props {
  form: any;
  name: string;
  label?: string;
  width?: string;
  height?: string;
}

const ReusableImageUpload: React.FC<Props> = ({
  form,
  name,
  label = "Image Upload",
  width = "100%",
  height = "100%",
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { control, setValue, watch } = form;

  const imageValue = watch(name);

  const imagePreview = useObjectURL(imageValue);

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (value: File) => void
  ) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        console.error("File is not an image.");
        return;
      }

      onChange(file);

      // const reader = new FileReader();
      // reader.onload = function (event) {
      //   const base64String = event.target?.result as string;
      //   setImagePreview(base64String);
      //   onChange(base64String);
      // };

      // reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setValue(name, null);
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDrop = (
    e: DragEvent<HTMLDivElement>,
    onChange: (value: File) => void
  ) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        console.error("File is not an image.");
        return;
      }

      onChange(file);
      // const reader = new FileReader();
      // reader.onload = function (event) {
      //   const base64String = event.target?.result as string;
      //   setImagePreview(base64String);
      //   onChange(base64String);
      // };

      // reader.readAsDataURL(file);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <FormControl
          fullWidth
          sx={{
            position: "relative",
            width,
            height,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "12px",
              border: imagePreview ? "none" : "2px dashed #688EA1",
              overflow: "hidden",
              backgroundColor: imagePreview ? "transparent" : "#F2F5F6",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsHovered(true);
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              setIsHovered(true);
            }}
            onDragLeave={() => setIsHovered(false)}
            onDrop={(e) => handleDrop(e, onChange)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            )}
            {!imagePreview && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "18px",
                    marginBottom: "6px",
                  }}
                >
                  {label}
                </Typography>
                <img
                  src="/assets/upload-img-icon.png"
                  alt="Upload"
                  onClick={handleButtonClick}
                  style={{
                    width: 40,
                    height: 40,
                    cursor: "pointer",
                    borderRadius: "50%",
                    // border: "2px solid #FFAD01",
                  }}
                />
                <Box sx={{ display: "flex", gap: 1, paddingX: 12 }}>
                  <button
                    type="button"
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={handleButtonClick}
                  >
                    <Typography
                      color="#2974B1"
                      fontSize={16}
                      sx={{ whiteSpace: "nowrap", fontWeight: 600 }}
                    >
                      Click to upload
                    </Typography>
                  </button>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color="#383D45"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    or drag and drop
                  </Typography>
                </Box>
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color="text.secondary"
                >
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </Typography>
                <input
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, onChange)}
                />
              </Box>
            )}
            {isHovered && imagePreview && (
              <CancelIcon
                sx={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  color: "#2974B1",
                  backgroundColor: "#ffff",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleDeleteImage}
              />
            )}
            {isHovered && imagePreview && (
              <Box
                sx={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "8px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  zIndex: 1,
                  border: "2px solid #FFAD01",
                }}
                onClick={handleButtonClick}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Change Image
                </Typography>
                <input
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, onChange)}
                />
              </Box>
            )}
          </Box>
        </FormControl>
      )}
    />
  );
};

export default ReusableImageUpload;
