import { Column } from "../reusable/layout";
import SideBarCard from "./cards/SideBarCard";

const TodaySideBar = () => {
  return (
    <Column
      sx={{
        padding: 1,
        width: "35%",
      }}
    >
      <SideBarCard />
      <SideBarCard />
      <SideBarCard />
      <SideBarCard />
    </Column>
  );
};

export default TodaySideBar;
