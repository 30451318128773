import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReusableTextField from "../reusable/Form/ReusableTextField";
import ReusableButton from "../reusable/Form/ReusableButton";
import { newPasswordSchema } from "../../schemas/user/reset-password";
import PasswordTextField from "../registration/PasswordTextField";
import { useSnackbar } from "../snackbar/Snackbar";

type Props = {
  code: string | null;
};

const EnterNewPassword = ({ code }: Props) => {
  const { handleSubmit, control } = useForm({
    defaultValues: { password: "", confirmPassword: "" },
    resolver: yupResolver(newPasswordSchema),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (value: {
    password: string;
    confirmPassword: string;
  }) => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_CORE_SERVICE_URL}/api/auth/reset-password`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
          password: value.password,
          passwordConfirmation: value.confirmPassword,
        }),
      }
    );
    setIsLoading(false);
    if (response.status === 200) {
      showSnackbar("success", "Password successfully changed!", 3000);
      navigate("/login");
    } else {
      showSnackbar("error", "Something went wrong!", 3000);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography component="h1" variant="h6" sx={{ whiteSpace: "nowrap" }}>
        Enter new password
      </Typography>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        sx={{ mt: 1 }}
      >
        <PasswordTextField
          inputType="password"
          control={control}
          name="password"
          id="password"
          label="Password"
          autoComplete="password"
        />
        <ReusableTextField
          inputType="password"
          control={control}
          name="confirmPassword"
          label="Confirm Password"
          autoComplete="confirmPassword"
        />
        <ReusableButton
          type="submit"
          onClickFunction={handleSubmit(onSubmit)}
          buttonText="Reset Password"
          color="#1C5553"
          backgroundColor="#D7E2E2"
          width="60%"
          isLoading={isLoading}
        />
      </Box>
    </Container>
  );
};

export default EnterNewPassword;
