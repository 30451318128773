import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
    allVariants: {
      color: "#101828",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#2974B1",
      light: "#A1C7E7",
    },
    background: {
      paper: "#E4E7EC",
    },
    secondary: {
      main: "#FFAD01",
      dark: "#68442A",
      light: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#7D8189",
    },
  },
});
