import { Grid } from "@mui/material";
import MyProgramsLeftCol from "../../components/my-programs/MyProgramsLeftCol";
import MyProgramsRightCol from "../../components/my-programs/MyProgramsRightCol";

const MyProgramsPage = () => {
  const leftColXs = 8.75;
  const rightColXs = 12 - leftColXs;

  return (
    <Grid container spacing={2} sx={{ p: 5 }}>
      <Grid item xs={leftColXs}>
        <MyProgramsLeftCol />
      </Grid>
      <Grid item xs={rightColXs}>
        <MyProgramsRightCol />
      </Grid>
    </Grid>
  );
};

export default MyProgramsPage;
