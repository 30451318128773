import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import MealLibrary from "../../components/program-library/meal/MealLibrary";
import MealLibraryFilters from "../../components/program-library/meal/MealLibraryFilters";
import WorkoutLibrary from "../../components/program-library/workout/WorkoutLibrary";
import WorkoutLibraryFilters from "../../components/program-library/workout/WorkoutLibraryFilters";
import {
  mealLibraryFiltersDefaultValues,
  mealLibraryFiltersSchema,
} from "../../schemas/program-library/meal";
import {
  workoutLibFiltersDefaultValues,
  workoutLibFiltersSchema,
} from "../../schemas/program-library/workout";

const tabs = ["Meals", "Workouts"];

const ProgramLibraryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const initialTab = query.get("tab") || tabs[0];

  const [tab, setTab] = useState<string>(initialTab);

  const mealFiltersForm = useForm({
    defaultValues: mealLibraryFiltersDefaultValues,
    resolver: yupResolver(mealLibraryFiltersSchema),
  });

  const workoutFiltersForm = useForm({
    defaultValues: workoutLibFiltersDefaultValues,
    resolver: yupResolver(workoutLibFiltersSchema),
  });

  useEffect(() => {
    navigate(`?tab=${tab}`, { replace: true });
  }, [tab, navigate]);

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, ml: 52, mt: 2 }}>
        {tabs.map((el, ind) => (
          <Typography
            key={ind}
            fontSize={24}
            fontWeight={600}
            color={el === tab ? "#2974B1" : "#7D8189"}
            sx={{ cursor: "pointer" }}
            onClick={() => setTab(el)}
          >
            {el}
          </Typography>
        ))}
      </Box>
      {tab === tabs[0] && (
        <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
          <MealLibraryFilters form={mealFiltersForm} />
          <MealLibrary form={mealFiltersForm} />
        </Box>
      )}
      {tab === tabs[1] && (
        <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
          <WorkoutLibraryFilters control={workoutFiltersForm.control} />
          <WorkoutLibrary form={workoutFiltersForm} />
        </Box>
      )}
    </Box>
  );
};

export default ProgramLibraryPage;
