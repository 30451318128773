/** @jsxImportSource @emotion/react */
import HelpIcon from "@mui/icons-material/Help";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { isEmpty } from "../../../utils/onboarding";
import styled from "@emotion/styled";

type Props = {
  control: Control<any>;
  name: string;
  inputType: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  placeholderColor?: string;
  autoComplete?: string;
  borderRadius?: string;
  borderColor?: string;
  backgroundColor?: string;
  tooltipText?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  autofocus?: boolean;
  onFocus?: any;
  hideValue?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  startAdornment?: any;
  endAdornment?: any;
  multiline?: boolean;
  rows?: number;
  helperText?: string;
  min?: number;
  max?: number;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const StyledTextField = styled(TextField)<{ readOnly: boolean }>`
  ${({ readOnly }) =>
    readOnly &&
    `
    & .MuiInputBase-root {
      color: grey;
      &:hover {
        background-color: transparent;
      }
    }
    & .MuiInputBase-input {
      cursor: default;
    }
  `}
`;

const ReusableTextField = ({
  control,
  name,
  inputType,
  label,
  sublabel,
  placeholder = "",
  autoComplete,
  borderRadius = "8px",
  borderColor = "#FFAD01",
  backgroundColor = "#fff",
  placeholderColor = "#818388",
  tooltipText = "",
  isRequired = true,
  fullWidth = true,
  autofocus = false,
  readOnly = false,
  disabled = false,
  startAdornment = null,
  endAdornment = null,
  multiline = false,
  hideValue = false,
  rows,
  helperText,
  min,
  max,
  onFocus,
  onBlur,
}: Props) => {
  const [isPassword, setIsPassword] = useState(inputType === "password");

  const handleClickIsPassword = () => setIsPassword(!isPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired,
      }}
      render={({
        field: { onChange, onBlur: controllerOnBlur, value, ref },
        fieldState: { invalid, error },
      }) => {
        const handleBlur = (
          event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          if (onBlur) {
            onBlur(event);
          }
          controllerOnBlur();
        };
        return (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "22px",
                textAlign: "left",
                marginBottom: "4px",
                color: "#383D45",
              }}
            >
              {label}
            </Typography>
            {sublabel && (
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  textAlign: "left",
                  marginBottom: "4px",
                  color: "#4F5359",
                }}
              >
                {sublabel}
              </Typography>
            )}
            <StyledTextField
              hiddenLabel
              sx={{
                mt: 0.5,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#2974B1",
                    borderWidth: "2px",
                  },
                  "&.Mui-error fieldset": {
                    borderColor: "#d32f2f",
                    borderWidth: "1px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px",
                    borderColor: "#EAECF0",
                  },
                  backgroundColor: backgroundColor,
                },
                "& ::placeholder": {
                  color: placeholderColor,
                  fontWeight: "400",
                  fontSize: "16px",
                },
                "& .MuiInputBase-root.Mui-disabled": {
                  backgroundColor: "#f0f0f0",
                },
                ...(inputType === "number" && {
                  '& input[type="number"]::-webkit-inner-spin-button': {
                    display: "none",
                  },
                  '& input[type="number"]::-webkit-outer-spin-button': {
                    display: "none",
                  },
                  '& input[type="number"]': {
                    "-moz-appearance": "textfield",
                  },
                }),
              }}
              size="small"
              multiline={multiline}
              rows={rows}
              ref={ref}
              value={isEmpty(value) || hideValue ? "" : value}
              onChange={onChange}
              onBlur={handleBlur}
              margin="normal"
              type={
                inputType === "password" && !isPassword ? "text" : inputType
              }
              name={name}
              placeholder={placeholder}
              required={isRequired}
              fullWidth={fullWidth}
              autoComplete={autoComplete}
              autoFocus={autofocus}
              onFocus={onFocus}
              error={invalid}
              helperText={error?.message || helperText}
              disabled={disabled}
              readOnly={readOnly}
              InputProps={{
                inputProps: inputType === "number" ? { min, max } : {},
                readOnly: readOnly,
                style: {
                  borderRadius: borderRadius,
                },
                startAdornment: startAdornment !== null && (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                ),
                endAdornment:
                  inputType === "password" ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickIsPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {isPassword ? (
                          <VisibilityOff
                            style={{ color: "#cfcfcf", userSelect: "none" }}
                          />
                        ) : (
                          <Visibility style={{ userSelect: "none" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <>
                      <InputAdornment position="end">
                        {endAdornment}
                      </InputAdornment>
                      {!!tooltipText && (
                        <InputAdornment position="end">
                          <Tooltip title={tooltipText}>
                            <HelpIcon
                              fontSize="small"
                              style={{ color: "#cfcfcf" }}
                            />
                          </Tooltip>
                        </InputAdornment>
                      )}
                    </>
                  ),
              }}
            />
          </Box>
        );
      }}
    />
  );
};

export default ReusableTextField;
