import { Badge, Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../config/mui-theme";
import {
  expertAndAdminNavigation,
  NAVBAR_HEIGHT,
} from "../../../constants/navigation/navigation";
import { expertRoutes } from "../../../constants/routes/expert";
import { Role } from "../../../enums/auth";
import { isInRole } from "../../../utils/auth";
import UserMenu from "./UserMenu";

type Props = {
  role: Role;
};

const Navbar = ({ role }: Props) => {
  const navs = isInRole(role, [Role.EXPERT, Role.ADMIN])
    ? expertAndAdminNavigation
    : [];

  const navigate = useNavigate();
  const location = useLocation();

  const isOnPage = (page: string) => {
    if (page === "/") {
      return location.pathname === page;
    }
    return location.pathname.includes(page);
  };

  return (
    <Box
      sx={{
        height: NAVBAR_HEIGHT,
        borderBottom: "1px solid #F2F4F7",
        px: "12px",
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        {/* Logo */}
        <Box sx={{ width: 100, height: 32, cursor: "pointer", mr: 10 }}>
          <img
            alt="logo"
            src={"/assets/navbar-logo.svg"}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>

        {/* Navs */}
        {navs.map((nav, ind) => (
          <Typography
            key={ind}
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: isOnPage(nav.route) ? "#2974B1" : "#344054",
              borderBottom: isOnPage(nav.route) ? "2px solid #FFAD01" : "none",
              p: "6px 12px",
              cursor: nav.isBeta ? "auto" : "pointer",
              position: "relative",
              userSelect: "none",
            }}
            onClick={() => !nav.isBeta && navigate(nav.route)}
          >
            {nav.text}
            {nav.isBeta && (
              <img
                alt="beta-tag"
                src="/assets/beta-tag.svg"
                style={{ position: "absolute", top: -7, right: -10 }}
              />
            )}
          </Typography>
        ))}

        {isInRole(role, [Role.EXPERT, Role.ADMIN]) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: isOnPage(expertRoutes.createProgram)
                ? "2px solid #FFAD01"
                : "none",
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,

                p: "6px 12px",
                cursor: "pointer",
                position: "relative",
                userSelect: "none",
                color: theme.palette.primary.main,
              }}
              onClick={() => navigate(expertRoutes.createProgram)}
            >
              New Program
            </Typography>
            <img alt="plus" src="/assets/plus-icon2.svg" />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            ml: "auto",
            mr: 1,
          }}
        >
          <Box sx={{ cursor: "pointer" }}>
            <Badge
              badgeContent={10}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: "10px",
                  padding: "0px",
                },
              }}
            >
              <img
                alt="cart"
                src="/assets/navbar-shop-icon.svg"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Badge>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <img
              alt="noty"
              src="/assets/navbar-notification-icon.svg"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <UserMenu role={role} />
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
