import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTypography = styled(Typography)<{ color?: string }>(
  ({ theme, color }) => ({
    color: color || theme.palette.text.primary,
    fontSize: 14,
  })
);

export const Comfortaa = styled(CustomTypography)<{ color?: string }>(() => ({
  fontFamily: "Comfortaa",
}));

export const OpenSans = styled(CustomTypography)<{ color?: string }>(() => ({
  fontFamily: "Open Sans",
}));

// #region Comfortaa
// #region Small Font Size
export const ComfortaaSmall400 = styled(Comfortaa)(() => ({
  fontSize: 12,
  fontWeight: 400,
}));

export const ComfortaaSmall500 = styled(Comfortaa)(() => ({
  fontSize: 12,
  fontWeight: 500,
}));

export const ComfortaaSmall600 = styled(Comfortaa)(() => ({
  fontSize: 12,
  fontWeight: 600,
}));

export const ComfortaaSmall700 = styled(Comfortaa)(() => ({
  fontSize: 12,
  fontWeight: 700,
}));
//#endregion

//#region SmallMedium Font Size
export const ComfortaaSmallMedium400 = styled(Comfortaa)(() => ({
  fontSize: 14,
  fontWeight: 400,
}));

export const ComfortaaSmallMedium500 = styled(Comfortaa)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

export const ComfortaaSmallMedium600 = styled(Comfortaa)(() => ({
  fontSize: 14,
  fontWeight: 600,
}));

export const ComfortaaSmallMedium700 = styled(Comfortaa)(() => ({
  fontSize: 14,
  fontWeight: 700,
}));
//#endregion

//#region Medium Font Size
export const ComfortaaMedium400 = styled(Comfortaa)(() => ({
  fontSize: 16,
  fontWeight: 400,
}));

export const ComfortaaMedium500 = styled(Comfortaa)(() => ({
  fontSize: 16,
  fontWeight: 500,
}));

export const ComfortaaMedium600 = styled(Comfortaa)(() => ({
  fontSize: 16,
  fontWeight: 600,
}));

export const ComfortaaMedium700 = styled(Comfortaa)(() => ({
  fontSize: 16,
  fontWeight: 700,
}));
//#endregion

// #region MediumLarge Font Size
export const ComfortaaMediumLarge400 = styled(Comfortaa)(() => ({
  fontSize: 20,
  fontWeight: 400,
}));

export const ComfortaaMediumLarge500 = styled(Comfortaa)(() => ({
  fontSize: 20,
  fontWeight: 500,
}));

export const ComfortaaMediumLarge600 = styled(Comfortaa)(() => ({
  fontSize: 20,
  fontWeight: 600,
}));

export const ComfortaaMediumLarge700 = styled(Comfortaa)(() => ({
  fontSize: 20,
  fontWeight: 700,
}));
//#endregion

// #region Large Font Size
export const ComfortaaLarge400 = styled(Comfortaa)(() => ({
  fontSize: 24,
  fontWeight: 400,
}));

export const ComfortaaLarge500 = styled(Comfortaa)(() => ({
  fontSize: 24,
  fontWeight: 500,
}));

export const ComfortaaLarge600 = styled(Comfortaa)(() => ({
  fontSize: 24,
  fontWeight: 600,
}));

export const ComfortaaLarge700 = styled(Comfortaa)(() => ({
  fontSize: 24,
  fontWeight: 700,
}));
//#endregion
// #endregion

// #region OpenSans
// #region Small Font Size
export const OpenSansSmall400 = styled(OpenSans)(() => ({
  fontSize: 12,
  fontWeight: 400,
}));

export const OpenSansSmall500 = styled(OpenSans)(() => ({
  fontSize: 12,
  fontWeight: 500,
}));

export const OpenSansSmall600 = styled(OpenSans)(() => ({
  fontSize: 12,
  fontWeight: 600,
}));

export const OpenSansSmall700 = styled(OpenSans)(() => ({
  fontSize: 12,
  fontWeight: 700,
}));
//#endregion

//#region SmallMedium Font Size
export const OpenSansSmallMedium400 = styled(OpenSans)(() => ({
  fontSize: 14,
  fontWeight: 400,
}));

export const OpenSansSmallMedium500 = styled(OpenSans)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

export const OpenSansSmallMedium600 = styled(OpenSans)(() => ({
  fontSize: 14,
  fontWeight: 600,
}));

export const OpenSansSmallMedium700 = styled(OpenSans)(() => ({
  fontSize: 14,
  fontWeight: 700,
}));
//#endregion

//#region Medium Font Size
export const OpenSansMedium400 = styled(OpenSans)(() => ({
  fontSize: 16,
  fontWeight: 400,
}));

export const OpenSansMedium500 = styled(OpenSans)(() => ({
  fontSize: 16,
  fontWeight: 500,
}));

export const OpenSansMedium600 = styled(OpenSans)(() => ({
  fontSize: 16,
  fontWeight: 600,
}));

export const OpenSansMedium700 = styled(OpenSans)(() => ({
  fontSize: 16,
  fontWeight: 700,
}));
//#endregion

// #region MediumLarge Font Size
export const OpenSansMediumLarge400 = styled(OpenSans)(() => ({
  fontSize: 20,
  fontWeight: 400,
}));

export const OpenSansMediumLarge500 = styled(OpenSans)(() => ({
  fontSize: 20,
  fontWeight: 500,
}));

export const OpenSansMediumLarge600 = styled(OpenSans)(() => ({
  fontSize: 20,
  fontWeight: 600,
}));

export const OpenSansMediumLarge700 = styled(OpenSans)(() => ({
  fontSize: 20,
  fontWeight: 700,
}));
//#endregion

// #region Large Font Size
export const OpenSansLarge400 = styled(OpenSans)(() => ({
  fontSize: 24,
  fontWeight: 400,
}));

export const OpenSansLarge500 = styled(OpenSans)(() => ({
  fontSize: 24,
  fontWeight: 500,
}));

export const OpenSansLarge600 = styled(OpenSans)(() => ({
  fontSize: 24,
  fontWeight: 600,
}));

export const OpenSansLarge700 = styled(OpenSans)(() => ({
  fontSize: 24,
  fontWeight: 700,
}));
//#endregion
// #endregion
