import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ProgramData } from "../../../schemas/programs/programs";

type Props = {
  programObject: ProgramData;
};

const ProgramCardBasicInfo: React.FC<Props> = ({ programObject }) => {
  return (
    <Box
      sx={{ width: "100%", display: "flex", gap: 1, flexDirection: "column" }}
    >
      <Button
        size="small"
        disabled={true}
        disableRipple
        startIcon={<img src="/assets/perce.svg" alt="perce-icon" />}
        sx={{
          textTransform: "none",
          border: "1px solid #D0D5DD",
          borderRadius: "10px",
          width: "fit-content",
          px: 2,
        }}
      >
        <Typography fontSize={14} color="#667185">
          {programObject.category ?? "Category"}
        </Typography>
      </Button>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 3,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",

            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Typography
              fontSize="32px"
              fontFamily="Comfortaa"
              fontWeight={700}
              sx={{
                backgroundImage: `linear-gradient(90deg, #F2B9FD 25%, #3992DD 100%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {programObject.programName ?? "Program name"}
            </Typography>
            <Box
              sx={{
                py: 0.5,
                px: 1.5,
                height: "max-content",
                borderRadius: 5,
                bgcolor: "#ECFDF3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#027A48">
                Active
              </Typography>
            </Box>
          </Box>
          <Typography
            fontFamily="Open Sans"
            fontWeight={400}
            fontSize="16px"
            color="#545F71"
          >
            {programObject?.objectives?.replace(/<\/?[^>]+(>|$)/g, "") ?? ""}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              py: 0.5,
              px: 1.5,
              height: "max-content",
              borderRadius: 5,
              background:
                "linear-gradient(to right, rgb(242, 185, 253, 0.4), rgb(57, 146, 221, 0.4))",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/heart-icon.svg"
              alt="Heart icon"
              style={{
                width: "16px",
                height: "16px",
                marginTop: "1px",
                marginRight: "6px",
              }}
            />
            <Typography
              fontSize={14}
              fontWeight={600}
              color="#2974B1"
              sx={{
                opacity: "100%",
              }}
            >
              {programObject.difficulty ?? "Beginner"}
            </Typography>
          </Box>
          <Typography
            fontFamily="Open Sans"
            fontWeight={600}
            fontSize="24px"
            color="#7D8189"
            sx={{ mt: 1 }}
          >
            <span
              style={{ color: "#F3AC38", fontWeight: 400, fontSize: "19px" }}
            >
              Last update:
            </span>{" "}
            12.08.2024
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramCardBasicInfo;
