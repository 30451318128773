import { Navigate, Route } from "react-router";
import { adminRoutes } from "../constants/routes/admin";
import { expertRoutes } from "../constants/routes/expert";
import {
  AccountDetailsPage,
  EditProgram,
  MainPage,
  MarketplacePage,
  ProgramLibraryPage,
  ProgramOverview,
  ProgramPage,
  Programs,
  SciencePage,
  UserManagement,
} from "../pages";

export const useAdminRoutes = () => {
  return (
    <>
      <Route path={adminRoutes.userManagement} element={<UserManagement />} />
      <Route
        path={expertRoutes.accountDetails}
        element={<AccountDetailsPage />}
      />
      <Route path={expertRoutes.programs} element={<Programs />} />
      <Route path={expertRoutes.createProgram} element={<ProgramPage />} />
      <Route path={expertRoutes.editProgram(":id")} element={<EditProgram />} />
      <Route
        path={expertRoutes.programOverview(":id")}
        element={<ProgramOverview />}
      />
      <Route path={expertRoutes.marketplace} element={<MarketplacePage />} />
      <Route
        path={expertRoutes.programLibrary}
        element={<ProgramLibraryPage />}
      />
      <Route path={expertRoutes.home} element={<MainPage />} />
      <Route path={expertRoutes.science} element={<SciencePage />} />

      <Route path="*" element={<Navigate replace to={expertRoutes.home} />} />
    </>
  );
};
