import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import {
  ComfortaaLarge600,
  ComfortaaSmallMedium400,
} from "../TodayLabels.style";
import { theme } from "../../../config/mui-theme";
import { Column, Row } from "../../reusable/layout";
import { ReusableButton } from "../../reusable";

const WelcomeSection = () => {
  return (
    <Row sx={{ alignItems: "center" }}>
      <img
        src="/assets/header-image.jpg"
        alt="Header"
        style={{ borderRadius: 16, height: 170 }}
      />
      <Column
        sx={{
          marginLeft: 4,
          alignItems: "flex-start",
        }}
      >
        <ComfortaaLarge600 color={theme.palette.primary.main}>
          Begin with your first Program
        </ComfortaaLarge600>
        <ComfortaaSmallMedium400 color={theme.palette.text.secondary}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </ComfortaaSmallMedium400>
        <ReusableButton
          width="auto"
          onClickFunction={() => alert("Explore Programs clicked")}
          buttonText="Explore Programs"
          backgroundColor={theme.palette.background.paper}
          color={theme.palette.primary.main}
          afterIcon={<img src={"/assets/arrow-right.svg"} alt={""} />}
        />
      </Column>
    </Row>
  );
};

export default WelcomeSection;
