import { Box, MenuItem, Modal, Select, Typography } from "@mui/material";
import { ReusableButton } from "../../reusable";

type Props = {
  workout: Workout;
  open: boolean;
  handleClose: () => void;
};

const WorkoutLibraryAddToProgramModal = ({
  workout,
  open,
  handleClose,
}: Props) => {
  const HeaderBadge = ({ text, icon }: { text: string; icon: string }) => {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", flexShrink: 0, gap: 1 }}
      >
        <img alt="badge" src={icon} />
        <Typography fontSize={16} fontWeight={700} color="#2974B1">
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "white",
          boxShadow: 5,
          outline: "none",
          borderRadius: "10px",
          maxHeight: "98vh",
          overflowY: "auto",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            width: "750px",
          }}
        >
          <Typography
            fontSize={40}
            fontWeight={400}
            color="#2974B1"
            fontFamily="Comfortaa"
          >
            {workout.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              width: "100%",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                fontSize={16}
                fontWeight={700}
                color="black"
                mr="auto"
              >
                {`${workout.createdBy.firstName} ${workout.createdBy.lastName}`}
              </Typography>
            </Box>

            <HeaderBadge
              icon="/assets/workout-difficulty-icon.svg"
              text={workout.difficulty}
            />
            <HeaderBadge
              icon="/assets/workout-participants.svg"
              text={`${111} Participants`}
            />
            <HeaderBadge
              icon="/assets/workout-calendar-icon.svg"
              text={`${workout.duration} min`}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={16} fontWeight={400} color="#2974B1">
              Fitness Instructor
            </Typography>
            {workout.equipment.length > 0 &&
              workout.equipment.length <= 3 &&
              workout.equipment[0] !== "" && (
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <img
                      alt="equipment"
                      src="/assets/workout-equipment-icon.svg"
                    />
                    <Typography fontSize={14} fontWeight={600} color="#2974B1">
                      Equipment needed:
                    </Typography>
                  </Box>
                  <ul
                    style={{
                      marginTop: 6,
                      paddingLeft: "20px",
                      listStyleType: "disc",
                    }}
                  >
                    {workout.equipment.map((el, ind) => (
                      <li key={ind} style={{ fontSize: 14, fontWeight: "400" }}>
                        {el}
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
          </Box>
          <Box
            sx={{
              width: 749,
              height: 409,
              borderRadius: 3,
              overflow: "hidden",
              mt: 2,
            }}
          >
            <img
              alt="meal"
              src={"/assets/workout-video-placeholder.png"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          {workout.equipment.length > 3 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <img alt="equipment" src="/assets/workout-equipment-icon.svg" />
                <Typography fontSize={14} fontWeight={600} color="#2974B1">
                  Equipment needed:
                </Typography>
              </Box>
              <ul
                style={{
                  marginTop: 6,
                  paddingLeft: "20px",
                  listStyleType: "disc",
                }}
              >
                {workout.equipment.map((el, ind) => (
                  <li key={ind} style={{ fontSize: 14, fontWeight: "400" }}>
                    {el}
                  </li>
                ))}
              </ul>
            </Box>
          )}
          <Typography
            fontSize={24}
            fontWeight={600}
            color="black"
            alignSelf="flex-start"
            mt={workout.equipment.length > 3 ? 0 : 3}
            mb={1}
          >
            Add Workout to Program
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              textAlign: "left",
              marginBottom: "4px",
              alignSelf: "flex-start",
            }}
          >
            Select the program from the list
          </Typography>
          <Select
            size="small"
            displayEmpty
            value={null}
            MenuProps={{
              sx: { height: "300px" },
            }}
            sx={{
              alignSelf: "flex-start",
              maxHeight: "50px",
              width: "100%",
              mt: 0.5,
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2974B1",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2974B1",
              },
            }}
            renderValue={(value: any) =>
              value ? (
                value
              ) : (
                <Typography color="grey">{"Select program"}</Typography>
              )
            }
          >
            <MenuItem value="__clear__">
              <Typography fontSize={16} fontWeight={400} color="grey">
                Select option
              </Typography>
            </MenuItem>
            {["Neki program"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 3,
            }}
          >
            <ReusableButton
              buttonText={"Cancel"}
              color="#4F5359"
              backgroundColor="#FFFFFF"
              width="50%"
              height="40px"
              onClickFunction={handleClose}
              border="1px solid #D0D5DD"
            />
            <ReusableButton
              buttonText={"Add to program"}
              width="50%"
              height="40px"
              onClickFunction={() => {}}
              backgroundColor="#2974B1"
              beforeIcon={<img src="/assets/icon-plus.svg" alt="icon" />}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 27,
              right: 22,
              cursor: "pointer",
              p: 1,
            }}
            onClick={handleClose}
          >
            <img
              src="/assets/close-icon.svg"
              alt="close"
              style={{
                width: "12px",
                height: "12px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default WorkoutLibraryAddToProgramModal;
