import { useInfiniteQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { ProgramsType } from "../../types/programs/ProgramsType";

interface UseProgramsQueryProps {
  name?: string;
  removePagination?: boolean;
}

export interface ProgramsData {
  data: ProgramsType;
  pagination: {
    pageCount: number;
    total: number;
  };
}

export function useProgramQuery(props: UseProgramsQueryProps) {
  const filters = [props?.name ? `name=${props?.name}` : null]
    .filter(Boolean)
    .join("&");

  return useInfiniteQuery({
    queryKey: ["api/healthProgram", props?.name],
    queryFn: async ({ pageParam }) => {
      const queryParameters = `page=${pageParam}&pageSize=3`;

      const endpoint = `${process.env.REACT_APP_CORE_SERVICE_URL}${
        props.removePagination
          ? "/api/health/program"
          : `/api/health/program?${queryParameters}${
              filters ? `&${filters}` : ""
            }`
      }`;

      const response = await coreServiceAxios.get<ProgramsData>(endpoint);

      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
}
