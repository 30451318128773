import { Box } from "@mui/material";
import { Routes } from "react-router-dom";
import { useAuthToken } from "../auth/authToken";
import Navbar from "../components/navigation/navbar/Navbar";
import Sidebar from "../components/navigation/sidebar/Sidebar";
import { NAVBAR_HEIGHT } from "../constants/navigation/navigation";
import { Role } from "../enums/auth";
import { isInRole } from "../utils/auth";
import { useAdminRoutes } from "./AdminRoutes";
import { useAnonymousRoutes } from "./AnonymousRoutes";
import { useExpertRoutes } from "./ExpertRoutes";
import { useSharedRoutes } from "./SharedRoutes";
import { useUserRoutes } from "./UserRoutes";
import { useUsersMeQuery } from "../api/users/usersMeQuery";

const AppRoutes = () => {
  const { hasToken: isSignedIn } = useAuthToken();
  const { data: user } = useUsersMeQuery();

  const role: Role = user?.userRole.role.type as Role;

  const sharedRoutes = useSharedRoutes();
  const expertRoutes = useExpertRoutes();
  const userRoutes = useUserRoutes();
  const adminRoutes = useAdminRoutes();
  const anonymousRoutes = useAnonymousRoutes();

  const roleRoutes = (() => {
    switch (role) {
      case Role.EXPERT:
        return expertRoutes;
      case Role.ADMIN:
        return adminRoutes;
      default:
        return null;
    }
  })();

  if (!isSignedIn) {
    return <Routes>{anonymousRoutes}</Routes>;
  }

  if (isInRole(role, Role.USER)) {
    return (
      <>
        <Navbar role={role} />
        <Box
          sx={{
            display: "flex",
            height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
            overflow: "hidden",
          }}
        >
          <Sidebar />
          <Box sx={{ width: "100%", height: "100%", overflowY: "auto" }}>
            <Routes>
              {sharedRoutes}
              {userRoutes}
            </Routes>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Navbar role={role} />
      <Routes>
        {sharedRoutes}
        {roleRoutes}
      </Routes>
    </>
  );
};

export default AppRoutes;
