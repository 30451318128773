import * as yup from "yup";

export const searchInputSchema = yup
  .object()
  .shape({
    searchInput: yup
      .string()
      .max(50, "Search input cannot exceed 50 characters"),
  })
  .required();
