import { Route } from "react-router-dom";
import { sharedRoutes } from "../constants/routes/shared";
import { AccountDetailsPage, ErrorPage } from "../pages";

export const useSharedRoutes = () => {
  return (
    <>
      <Route
        path={sharedRoutes.accountDetails}
        element={<AccountDetailsPage />}
      />

      <Route
        path={sharedRoutes.serverError}
        element={<ErrorPage variant="server-error" />}
      />
      <Route
        path={sharedRoutes.notFound}
        element={<ErrorPage variant="not-found" />}
      />
      <Route
        path={sharedRoutes.somethingWrong}
        element={<ErrorPage variant="something-wrong" />}
      />
    </>
  );
};
