import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { User } from "../../types/user/user";

export function useUsersMeQuery() {
  const { hasToken } = useAuthToken();

  return useQuery({
    queryKey: ["/me"],
    queryFn: async () => {
      const result = await coreServiceAxios.get<User>("/api/users/me");
      return result.data;
    },
    enabled: hasToken,
  });
}
