import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";

interface RowProps {
  children: ReactNode;
  span?: number;
  width?: string | number;
  sx?: SxProps;
  onClick?: any;
}

export const Row = ({ children, onClick, ...props }: RowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "center",
        ...props.sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export const Column = ({ children, ...props }: RowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
};
