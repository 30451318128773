import { Box, Button, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { ProgramType } from "../../../types/programs/ProgramsType";

type Props = {
  programObject: ProgramType;
};

const ProgramCardInfo: React.FC<Props> = ({ programObject }: Props) => {
  const totalSupplementsCost = useMemo(() => {
    return programObject?.supplements?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.cost,
      0
    );
  }, [programObject?.supplements]);

  const totalTestingProtocolsCost = useMemo(() => {
    return programObject?.testingProtocols?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.cost,
      0
    );
  }, [programObject?.testingProtocols]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 1,
        flexDirection: "column",
        pl: 4,
        pt: 2,
        pb: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          color="#000000"
          fontFamily="Comfortaa"
          sx={{
            width: "100%",
          }}
        >
          {programObject.name ?? "Program name"}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            disabled={true}
            disableRipple
            startIcon={<img src="/assets/medal.svg" alt="medal-icon" />}
            sx={{
              textTransform: "none",
              border: "1px solid #D0D5DD",
              borderRadius: "10px",
              width: "fit-content",
              px: 2,
            }}
          >
            <Typography fontSize={14} color="#667185">
              {programObject.difficulty ?? "Beginner"}
            </Typography>
          </Button>
          <Box
            sx={{
              py: 0.5,
              px: 1.5,
              height: "max-content",
              borderRadius: 5,
              color: "#2974B1",
              bgcolor: "#A1C7E7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize="14px" fontWeight={600} color="#2974B1">
              Active
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: "33%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <img
              alt="marker"
              src="/assets/marker.svg"
              style={{
                height: "16px",
                width: "14px",
                objectFit: "fill",
              }}
            />
            <Typography fontSize="16px" fontWeight={600} color="#2974B1">
              New South Wales
            </Typography>
          </Box>
          <Typography
            fontFamily="Open Sans"
            fontWeight={600}
            fontSize="16px"
            color="#101828"
          >
            <span
              style={{
                color: "#F3AC38",
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "Open Sans",
              }}
            >
              Start Date:
            </span>{" "}
            12.08.2024
          </Typography>
          <Typography fontSize="12px" fontWeight={600} color="#7D8189">
            DESCRIPTION
          </Typography>
          <Typography fontSize="12px" fontWeight={600} color="#000000">
            {programObject?.objectives?.replace(/<\/?[^>]+(>|$)/g, "") ?? ""}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            backgroundColor: "#F9FAFB",
            border: "1px solid #F2F4F7",
            borderRadius: "8px",
            p: "16px",
            overflowY: "auto",
            maxHeight: "220px",
            scrollbarWidth: "thin",
          }}
        >
          <Typography
            fontSize="12px"
            fontWeight={600}
            color="#7D8189"
            fontFamily="Open Sans"
          >
            Testing Protocols
          </Typography>
          {programObject?.testingProtocols?.map((tp) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#EAECF0",
                  borderRadius: "180px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img
                  alt="program"
                  src={"/assets/header-image.jpg"}
                  style={{
                    height: "32px",
                    width: "32px",
                    borderRadius: "180px",
                  }}
                />
                <Typography
                  fontSize="12px"
                  fontWeight={600}
                  color="#2974B1"
                  fontFamily="Open Sans"
                >
                  {tp.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "33%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            justifyContent: "space-between",
            gap: 1,
            backgroundColor: "#F9FAFB",
            border: "1px solid #F2F4F7",
            borderRadius: "8px",
            p: "16px",
            overflowY: "auto",
            maxHeight: "220px",
            scrollbarWidth: "thin",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              fontSize="12px"
              fontWeight={600}
              color="#7D8189"
              fontFamily="Open Sans"
            >
              Supplements
            </Typography>
            {programObject?.supplements?.map((s) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "#EAECF0",
                    borderRadius: "180px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    alt="program"
                    src={"/assets/header-image.jpg"}
                    style={{
                      height: "32px",
                      width: "32px",
                      borderRadius: "180px",
                    }}
                  />
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="#2974B1"
                    fontFamily="Open Sans"
                  >
                    {s.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#4F5359"
                fontFamily="Open Sans"
              >
                Supplements
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#7D8189"
                fontFamily="Open Sans"
              >
                {`$${totalSupplementsCost}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#4F5359"
                fontFamily="Open Sans"
              >
                Testing protocols
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#7D8189"
                fontFamily="Open Sans"
              >
                {`$${totalTestingProtocolsCost}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight={700}
                color="#000000"
                fontFamily="Open Sans"
              >
                Financial Summary
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#FFAD00"
                fontFamily="Open Sans"
              >
                {`$${totalTestingProtocolsCost + totalSupplementsCost}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramCardInfo;
